<mat-form-field class="gwc-autocomplete">
	<mat-label>{{ props.label }}</mat-label>
	<input #autocomplete_input
		type="text"
		placeholder="Pick one"
		matInput
		[required]="required"
		[formControl]="formControl"
		[matAutocomplete]="auto"
		(input)="filterOptions()"
		(focus)="filterOptions()">
	<mat-autocomplete requireSelection
		(opened)="resetFilteredOptions()"
		#auto="matAutocomplete"
		[displayWith]="displayFunction">
		@if (filteredCommonlyUsed.length > 0) {
			<mat-optgroup label="Commonly Used"
				class="gwc-autocomplete__optgroup">
				@for (option of filteredCommonlyUsed; track option) {
					<mat-option [value]="option.value">
						{{ option.label }}
					</mat-option>
				}
			</mat-optgroup>
			<mat-optgroup label="Full List"
				class="gwc-autocomplete__optgroup">
				@for (option of filteredOptions; track option) {
					<mat-option [value]="option.value">
						{{ option.label }}
					</mat-option>
				}
			</mat-optgroup>
		} @else {
			@for (option of filteredOptions; track option) {
				<mat-option [value]="option.value">
					{{ option.label }}
				</mat-option>
			}
		}
	</mat-autocomplete>
	<mat-error>
		@if (showError) {
			@if (formControl.hasError('required')) {
				This field is required.
			}
		}
	</mat-error>
</mat-form-field>

