<mat-form-field class="gw-chkout-field gw-chkout-field--half"
	[hideRequiredMarker]="true">
	<mat-label>Enter Address</mat-label>
	<input matInput
		(blur)="hidePredictions()"
		[formControl]="formControl"
		#shippingAddressInput>
	<mat-error>
		@if (formControl.hasError('required')) {
			Please enter a valid address.
		} @else if (formControl.hasError('selected')) {
			Please choose an address from the list
		}
	</mat-error>
</mat-form-field>

@if (predictions.length > 0 && showPredictions) {
	<div class ="gwc-google-predictions">
		<mat-list>
			@for (prediction of predictions; track prediction) {
				<mat-list-item (click)="selectPrediction(prediction)">
					<div class="gwc-google-predictions__item">
						<mat-icon 
							svgIcon="location" 
							class="gw-order-location__on">
						</mat-icon>
						<span 
							[innerHTML]="highlightMatch(prediction.description, formControl.value)" 
							class="gwc-google-predictions__item-element">
						</span>
					</div>
				</mat-list-item>
				@if(!$last) {
					<mat-divider></mat-divider>
				}
			}
		</mat-list>
	</div>
}