<section *ngIf="field && field.fieldGroup 
	&& field.fieldGroup[current_id] !== undefined 
	&& field.fieldGroup[current_id].props !== undefined"
	class="gwc-step"
	[class.gwc-step--facility]="field.fieldGroup[current_id].props?.['type'] === 'facility_finder'">
	<div class="gwc-step__body">
		<div *ngIf="field.fieldGroup[current_id].props?.['type'] !== 'facility_finder'"
			class="gwc-step__top">
			<img class="gwc-step__icon"
				alt="step-icon"
				src="assets/wizard/{{field.fieldGroup[current_id].props?.['icon']}}.svg"/>
			<h1>
				{{ field.fieldGroup[current_id].props?.label }}
			</h1>
			<h2 *ngIf="field.fieldGroup[current_id].props?.['hint']">
				{{ field.fieldGroup[current_id].props?.['hint'] }}
			</h2>
		</div>

		@if (['passport', 'idp'].includes(props['product_type'])) {
			<formly-form *ngFor="let step of field.fieldGroup; let i = index"
				[class.gwc-step__form--hide]="i !== current_id"
				[form]="stepForms[i]"
				[model]="model"
				[fields]="step.fieldGroup || []"
				[options]="getOptions(i)"
			></formly-form>
		} @else {
			<formly-form [form]="stepForms[current_id]"
				[model]="model"
				[fields]="[field.fieldGroup[current_id]]"
				[options]="getOptions(current_id)">
			</formly-form>
		}

		<button	mat-icon-button
			class="gwc-step__close"
			(click)="closeForm()">
			<svg>
				<use href="assets/svg/shared.svg#close"/>
			</svg>
		</button>
	</div>
	<div class="gwc-step__buttons">
		<button mat-button
			type="button"
			class="gwc-step__buttons-btn"
			[disabled]="current_id === 0"
			(click)="stepChange('back')">
			Back
		</button>
		@if (steps) {
			{{ current_step }} of {{ steps }} 

			@if (['passport', 'idp'].includes(props['product_type'])) {
				| {{progress}}%
			}
		}

		@if (current_id + 1 === field.fieldGroup.length) {
			<button mat-flat-button
				color="primary"
				type="button"
				(click)="finishForm()">
				Finish
			</button>
		} @else {
			<button mat-flat-button
				color="primary"
				type="button"
				(click)="stepChange('continue')">
				Continue
			</button>
		}
	</div>
</section>
