import { Injectable } from '@angular/core'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})

export class MailService  extends ApiService{
  public getAllMessages() {
    return this.getRequest('mail/messages')
  }

  public getMessage(uuid: string) {
    return this.getRequest(`mail/message/${uuid}`)
  }
}
