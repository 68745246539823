<gwc-traveler-dialog
  [full]="true"
  [label]="actionLabel"
  (close)="dialogRef.close()">
  <ng-container body>
    <div class="gwc-ship-date__step"
      *ngIf="shipStep === 'pick'">
      <img src="/assets/svg/icon-step-travel-dates.svg" />
      <h2 class="gwc-ship-date__title">
        Select a document shipping date
      </h2>
      <div class="gwc-ship-date__disclosure">
        <span>Very Important.</span> In order to get your passport processed in the time you need it, it's imperative that you send in all of your documents in for processing on the day that you select <i>before 3 PM</i>. 
      </div>
      <div class="gwc-ship-date__dates">
        <button *ngFor="let date of dates"
          mat-flat-button
          color="primary"
          class="gwc-ship-date__dates-item"
          [class.gwc-ship-date__dates-item--active]="date.toFormat('MM/dd/yyyy') === promise_date.value"
          (click)="dateSelected(date)">
          {{ date.toFormat('cccc, MM/dd/yyyy') }}
        </button>
      </div>
      <div class="gwc-ship-date__form">
        <form [formGroup]="dateForm">
          <mat-form-field appearance="fill"
            [hideRequiredMarker]="true"
            class="gwc-ship-date__datepicker">
            <mat-label>Shipping Date</mat-label>
            <input matInput 
              mask="M0/d0/0000"
              [leadZeroDateTime]="true"
              [dropSpecialCharacters]="false"
              formControlName="promise_date"
              placeholder="mm/dd/yyyy"
              autocomplete="off">
            <input type="hidden"
              [value]="getDate(promise_date)"
              (dateChange)="dateSelected($event.value)"
              [matDatepicker]="ship_datepicker"
              [min]="minDate" 
              [max]="maxDate">
            <mat-datepicker-toggle matSuffix
              [for]="ship_datepicker">
            </mat-datepicker-toggle>
            <mat-datepicker #ship_datepicker></mat-datepicker>
            <mat-error>
              {{getErrorMessage()}}
            </mat-error>
          </mat-form-field>
        </form>
      </div>
    </div>
    <div class="gwc-ship-date__step"
      *ngIf="shipStep === 'confirm'">
      <img src="/assets/img/img-confirm-govy@2x.png" 
        alt="Icon" 
        role="presentation"> 
      <div class="gwc-ship-date__title gwc-ship-date__title--small">
        {{ data.traveler.first_name }}, looks like you're sending us your documents on:
      </div>
      <div class="gwc-ship-date__promise"> 
        {{ promise_date.value | date: 'EEEE, MM/dd/yyyy' }}
      </div>
      <div class="gwc-ship-date__disclosure gwc-ship-date__disclosure--wide">
        It is important they you realize that we are placing a reservation on your behalf in order to process your passport with the U.S. Government. If you fail to ship your documents on the date you promised, you will be charged the full service fee.
      </div>
      <div class="gwc-ship-date__confirm">
        <div class="gwc-ship-date__confirm-note">
          Please enter your full name "{{ data.traveler.friendly_name }}": 
        </div>
        <div>
          <form [formGroup]="confirmForm">
            <div class="gwc-ship-date__confirm-form">
              <mat-form-field floatLabel="auto"
                [hideRequiredMarker]="true"
                class="gwc-ship-date__field"
                [ngClass]="{ 'gwc-ship-date__field--confirmed': confirmed }">
                <mat-label>Your Name</mat-label>
                <input matInput 
                  placeholder="Your Name"
                  formControlName="confirm"
                  autocomplete="off">
              </mat-form-field>
              <gwc-button color="primary"
                [fullwidth]="true"
                [disabled]="!confirmForm.valid || confirmed"
                aria-label="Confirm"
                type="button"
                (callback)="confirmedSigned()"
                class="gwc-ship-date__confirm-button"
                [ngClass]="{ 'gwc-ship-date__confirm-button--confirmed': confirmed }">
                <div>
                  Confirm
                </div>
              </gwc-button>
            </div>
          </form>
        </div>
        <div class="gwc-ship-date__confirm-note gwc-ship-date__confirm-note--red">
          I acknowledge that if I miss my promised shipping date, I will be subject to being charged a full service fee.
        </div>
      </div>
    </div>
    <div class="gwc-ship-date__step gwc-ship-date__step--review"
      *ngIf="shipStep === 'done'">
      <div class="gwc-ship-date__review"> 
        <h3>Thanks for your submission.<br/> You're Almost Done!</h3> 
        <p><strong>Please call us now to complete your process at {{ manifest_phone }}</strong><br><br> We'll conduct a final review of your passport documents and answer any questions you may have before you ship us your documents.</p>
      </div>
      <img src="/assets/order/img-app-review-govy@2x.png"/>
    </div>
  </ng-container>
  <ng-container actions>
    <div class="gwc-ship-date__messages">
      {{ actionbarTitle }}
      <p>{{ actionbarMessage }}</p>
    </div>
    <gwc-button color="primary"
      type="button"
      class="gwc-ship-date__button"
      [fullwidth]="true"
      [disabled]="canProceed()"
      (callback)="proceed()">
      <div>
        {{ actionbarButton }}
        <svg *ngIf="shipStep === 'done'">
          <use href="assets/svg/shared.svg#download" />
        </svg>
      </div>
    </gwc-button>
  </ng-container>
</gwc-traveler-dialog>
