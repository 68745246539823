import { Component, OnInit, signal, WritableSignal } from '@angular/core'
import { Router, RoutesRecognized } from '@angular/router'
import { BrowserCheckService } from './services/browser-check.service'

@Component({
  selector: 'gwc-root',
  template: `
    @if (!isBrowserOutdated()) {
      <router-outlet></router-outlet>
    } @else {
      <p>Please use a newer device to take your photo</p>
    }
  `,
  styles: []
})

export class AppComponent implements OnInit {
  title = 'ng-client'
  public isBrowserOutdated: WritableSignal<boolean> = signal<boolean>(false)

  constructor(
    private router: Router,
    private browserCheckService: BrowserCheckService
  ){}

  public ngOnInit(): void {
    this.router.events
      .subscribe(event => {
        if (event instanceof RoutesRecognized) {
          if (event.url.includes('/#!')) {
            window.location.replace(event.url.replace('/#!', ''))
          }
          window.scrollTo(0,0)
        }
      })
    
    this.isBrowserOutdated.set(this.browserCheckService.isBrowserOutdated())
  }
}
