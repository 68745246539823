import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { TravelerDialogComponent } from './traveler.dialog.component'
import { HeaderModule } from '../header/header.module'

@NgModule({
  declarations: [
    TravelerDialogComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    HeaderModule
  ],
  exports: [
    TravelerDialogComponent
  ]
})

export class TravelerDialogModule { }
