import { FormGroup, AbstractControl } from '@angular/forms'

export function validatePasswordMatch() {
    return (control: AbstractControl<any, any>) => {
			let group = control.getRawValue()

			if (group.password 
				&& group.password_confirmation
				&& group.password !== group.password_confirmation) {
					return {'mismatch': true}
				} 
	
			return null
    }
}