<div class="gwc-card gwc-profile-card">
    <img [src]="profile.avatar" />
    <h2>
        {{ profile.first_name}} {{ profile.last_name }}
    </h2>
    <p>Birthday: {{ profile.date_of_birth | date }}</p>
    <!-- TODO: add functionality on how to know if the profile is complete -->
    <!-- TODO: add functionality to the button -->
    <button mat-stroked-button 
        class="gwc-profile-card__btn"
        color="primary">
        View Details
    </button>
</div>