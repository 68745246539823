<div class="gwc-layout">
	<mat-sidenav-container class="gwc-layout-sidenav-container">
		<mat-sidenav #snav
			class="gwc-layout-sidenav">
			<mat-nav-list>
				<a mat-list-item
					class="gwc-layout-sidenav__link"
					[routerLink]="['/orders']"
					routerLinkActive="gwc-layout-sidenav__link--active"
					(click)="snav.toggle()">
					My Orders
				</a>
				<a mat-list-item
					class="gwc-layout-sidenav__link"
					[routerLink]="['/inbox']"
					routerLinkActive="gwc-layout-sidenav__link--active"
					(click)="snav.toggle()">
					Message Center
				</a>
				<a mat-list-item
					class="gwc-layout-sidenav__link"
					(click)="logout()">
					Logout
				</a>
			</mat-nav-list>
		</mat-sidenav>
		<mat-sidenav-content class="gwc-layout-sidenav__content">
			<header class="gwc-header">
				<section class="gwc-header__section">
					<mat-toolbar class="gwc-header-toolbar">
						<div>
							@if (source) {
								<img class="gwc-header__logo"
									src=""/>
							}
							<button mat-icon-button 
								class="gwc-header-toolbar__menu"
								(click)="snav.toggle()">
								<mat-icon>menu</mat-icon>
							</button>
						</div>
						<div class="gwc-header-toolbar__panel">
							@if (phone) {
								<span class="gwc-header__questions">
									Questions? {{ phone }}
								</span>
							}
							<button mat-button 
								[matMenuTriggerFor]="menu"
								class="gwc-header__avatar">
								<img *ngIf="user"
									[src]="user.avatar" />
							</button>
						</div>
					</mat-toolbar>
				</section>
				<section *ngIf="order"
					class="gwc-header__section gwc-header-order">
					<div class="gwc-header-order__label">
						{{ order.label }}
					</div>
					<div class="gwc-header-order__data">	
						Placed on: {{ order.paid_at | date: 'MMM d, yyy' }} | Order Number: {{ order.order_number }}
					</div>
				</section>
			</header>
			<main class="gwc-layout-content">
				<ul class="gwc-layout-breadcrumbs">
					<li class="gwc-layout-breadcrumbs__item">
						<a [routerLink]="['/orders']">
							<svg>
								<use href="assets/svg/shared.svg#home"/>
							</svg>
							<span>My Orders</span>
						</a>
					</li>
					<li class="gwc-layout-breadcrumbs__item"
						*ngFor="let breadcrumb of breadcrumbs">
						<span class="gwc-layout-breadcrumbs__separator">
							>
						</span>
						<a [routerLink]="breadcrumb.url">
							{{ breadcrumb.label }}
						</a>
					</li>
				</ul>
				<ng-content></ng-content>
			</main>
		</mat-sidenav-content>
	</mat-sidenav-container>
	<mat-menu #menu="matMenu" class="gwc-layout__dropmenu">
		<button mat-menu-item
			(click)="logout()">
			Logout
		</button>
	</mat-menu>
</div>
