import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { catchError, map, Observable, of, throwError } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Router } from '@angular/router'
import { FormBuilder } from '@angular/forms'

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  constructor(
    public http: HttpClient,
    public router: Router,
    public formBuilder: FormBuilder
  ) { }

  private options = {
    withCredentials: true
  }

  getRequest(url: string, data?: any): Observable<any> {
    return this.http.get(`${environment.API}${url}`, {...this.options, params: data})
      .pipe(
        map((result: any) => {
          if (result && !result.meta && !result.per_page) {
            return result.data
          }

          return result
        }),
        catchError((error: HttpErrorResponse) => this.handleUnauthorized(error, url))
      )
  }

  postRequest(url:string, data: any): Observable<any> {
    return this.http
      .post<any>(`${environment.API}${url}`, data, this.options)
      .pipe(
        map((result: any) => {
          if (result && !result.meta && !result.per_page) {
            return result.data
          }

          return result
        }),
        catchError((error: HttpErrorResponse) => this.handleUnauthorized(error, url))
      )
  }

  private handleUnauthorized(error: HttpErrorResponse, url: string) {
    if ([401, 403].includes(error.status) && !url.includes('auth')) {
      this.router.navigate(['/login'])
    }

    // Hack for now
    if (url.includes('scanner/open')) {
      return throwError(() => error)
    }

    return of(error)
  }
}
