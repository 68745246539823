import { FormlyFieldConfig } from "@ngx-formly/core"

export const EMPLOYMENT_HISTORY_ENTRY = [
  {
    key: "type",
    type: "buttons",
    props: {
      options: [
        {
          label: "Employed",
          value: "employed"
        },
        {
          label: "School",
          value: "school"
        },
        {
          label: "Unemployed",
          value: "unemployed"
        }
      ],
      required: true
    },
    className: "gwc-form__field gwc-form__field--tight"
  },
  {
    key: "info",
    fieldGroup: [
      {
        key: "name",
        type: "input",
        props: {
          label: "Employer,School or other",
          required: true
        },
        className: "gwc-form__field gwc-form__field--grow gwc-form__field--tight"
      },
      {
        key: "field",
        type: "input",
        props: {
          label: "Field of Employment/Studies",
          required: false
        },
        className: "gwc-form__field gwc-form__field--grow gwc-form__field--tight"
      }
    ],
    fieldGroupClassName: "gwc-form__field-group",
    expressions: {
      'hide': (field: FormlyFieldConfig) => {
        return false
      }
    }
  },
  {
    fieldGroup: [
      {
        key: "phone_number",
        type: "masked-input",
        props: {
          mask: "(000) 000-0000",
          label: "Phone Number",
          required: true
        },
        className: "gwc-form__field gwc-form__field--grow gwc-form__field--grow"
      },
      {
        key: "address.country",
        type: "country-autocomplete",
        className: "gwc-form__field gwc-form__field--grow gwc-form__field--tight"
      }
    ],
    fieldGroupClassName: "gwc-form__field-group"
  },
  {
    key: "address",
    type: "address-autocomplete"
  },
  {
    type: "from-to"
  }
]