import { Component, OnInit } from '@angular/core'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { Router } from '@angular/router'
import { ButtonComponent } from 'src/app/components/button/button.component'
import { UserService } from 'src/app/services/user.service'
import { PasswordErrorStateMatcher } from 'src/matchers/password.error.matcher'
import { validatePasswordMatch } from 'src/validators/password.validator'

@Component({
  selector: 'gwc-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule
  ]
})

export class SetupComponent implements OnInit {
  public submitting: boolean = false
  public step: string = 'password'
  public alias!: {
    alias: string
    domain: string
  }
  public edit: boolean = false
  public aliasControl = this.formBuilder.control('', Validators.required)
  public passwordErrorStateMatcher = new PasswordErrorStateMatcher()
  public setupForm = this.formBuilder.nonNullable.group({
    password: ['', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern(/^(?=.*[A-Za-z]{1,})(?=.*\d{1,})(?=.*[\W_]{1,})[\w\W_]*$/i)
    ]],
    password_confirmation: ['', Validators.required]
  }, {validators: [validatePasswordMatch()]})

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getUserAlias()
  }

  private getUserAlias(): void {
    this.userService.getUserAlias()
      .subscribe(response => {
        let parts: string[] = response.email.split('@')
        this.alias = {
          alias: parts[0],
          domain: parts[1]
        }

        this.aliasControl.patchValue(this.alias.alias)
      })
  }

  public changeStep(): void {
    if (this.setupForm.valid) {
      this.step = this.step === 'password' ? 'alias':'password'
    }
  }

  public editAlias(): void {
    this.edit = true
  }

  public cancelEdit(): void {
    this.edit = false
    this.aliasControl.patchValue(this.alias.alias)
    this.aliasControl.markAsUntouched()
  }

  public saveAlias(): void {
    let new_alias = this.aliasControl.value

    if (new_alias) {
      this.userService.saveUserAlias(new_alias)
        .subscribe(response => {
          if (response.status === 409) {
            this.aliasControl.setErrors({taken: true})
          } else {
            this.alias.alias = new_alias as string
            this.edit = false
          }
        })
    }
  }

  public setupAccount(): void {
    this.userService.createPassword(this.setupForm.getRawValue())
      .subscribe(response => {
        this.router.navigate(['/orders'])
      })
  }
}
