import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'gwc-dialog-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  @Input() shadow: boolean = false
  @Input() label!: string
  @Input() close: boolean = true
  @Input() darkBackground: boolean = true
}
