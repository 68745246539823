import { Component, Inject } from '@angular/core'
import { FormGroup, FormControl, FormBuilder } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ReviewChecks } from 'src/types/order'

@Component({
  selector: 'gwc-download-packet',
  templateUrl: './download.packet.component.html',
  styleUrls: ['./download.packet.component.scss']
})

export class DownloadPacketComponent {
  public reviewForm = this.formBuilder.group({
    ds_application: [false],
    government_fees: [false],
    passport_photo: [false],
    final_check: [false],
    no_inbound_shipping: [false]
  })

  constructor(
    private formBuilder: FormBuilder,
    private downloadPacket: MatDialogRef<DownloadPacketComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReviewChecks
  ) {}

  public allChecks(): boolean {
    let formValue = this.reviewForm.getRawValue()

    return (
      formValue.ds_application === true &&
      (formValue.government_fees === true || this.data.gov_fee_included == true) &&
      formValue.passport_photo === true &&
      formValue.final_check === true && 
      (!this.data.adjudicated || formValue.no_inbound_shipping === true)
    )
  }

  public downloadDocuments(): void {
    this.downloadPacket.close({ accepted: true })
  }
}
