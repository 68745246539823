import { Component, ElementRef, ViewChild } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'gwc-esign.disclaimer',
  templateUrl: './esign.disclaimer.component.html',
  styleUrls: ['./esign.disclaimer.component.scss']
})

export class EsignDisclaimerComponent {
  @ViewChild('agreement') public checkbox_element!: MatCheckbox
  public agreement = this.formBuilder.control(false, [Validators.requiredTrue])

  constructor(
    public dialogRef: MatDialogRef<EsignDisclaimerComponent>,
    private formBuilder: FormBuilder
  ) {}

  public confirm() {
  }
}
