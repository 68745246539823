import { Injectable } from '@angular/core'
import { BehaviorSubject, map, Subject } from 'rxjs'
import { Application, Traveler } from 'src/types/traveler'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})

export class TravelerService extends ApiService {
  public updateTravelerToPending(traveler_uuid: string) {
    return this.postRequest(`traveler/${traveler_uuid}/manage/profile`, {})
  }
}
