<gwc-layout>
	<section *ngIf="user"
		class="gwc-home">
		<div class="gwc-home__menu">
			<div class="gwc-home__user gwc-card">
				<img [src]="user.avatar" />
				<h2>
					Welcome, {{ user.first_name }}
				</h2>
				<div>{{ user.first_name}} {{ user.last_name }}</div>
				<div>{{ user.email }}</div>
			</div>
			<a class="gwc-home__link gwc-card"
				[routerLink]="['/orders']"
				routerLinkActive="gwc-home__link--active">
				My Orders
			</a>
			<!-- <a class="gwc-home__link gwc-card"
				[routerLink]="['/profiles']"
				routerLinkActive="gwc-home__link--active">
				My Profiles
			</a> -->
			<a class="gwc-home__link gwc-card"
				[routerLink]="['/inbox']"
				routerLinkActive="gwc-home__link--active">
				Message Center
			</a>
		</div>
		<router-outlet></router-outlet>
	</section>
</gwc-layout>
