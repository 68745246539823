import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router, RouterModule } from '@angular/router'
import { ApplicationService } from 'src/app/services/application.service'
import { OrderService } from 'src/app/services/order.service'
import { Application, Traveler } from 'src/types/traveler'
import { environment } from 'src/environments/environment'
import { StatusMessageComponent } from './status.message/status.message.component'
import { MatDialog } from '@angular/material/dialog'
import { ShipDateComponent } from 'src/app/dialogs/ship.date/ship.date.component'
import { ReviewChecks } from 'src/types/order'
import { DownloadPacketComponent } from 'src/app/dialogs/download.packet/download.packet.component'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { ButtonComponent } from 'src/app/components/button/button.component'
import { ItineraryWidgetComponent } from '../itinerary.widget/itinerary.widget.component'
import { MatButtonModule } from '@angular/material/button'

@Component({
  selector: 'gwc-traveler',
  templateUrl: './traveler.component.html',
  styleUrls: ['./traveler.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    ItineraryWidgetComponent,
    MatButtonModule,
    MatProgressBarModule,
    RouterModule,
    StatusMessageComponent
  ]
})

export class TravelerComponent implements OnInit {
  @ViewChild(StatusMessageComponent) statusMessageComponent!: StatusMessageComponent

  public params: {[key: string]: string} = {}
  public traveler!: Traveler
  public model!: any
  public submitting: string[] = []
  public swapping: string = ''
  public message: string = ''
  public api = environment.API
  private passport_products: any = {
    'child-passport': 'Child Passport',
    'passport-renewal': 'Passport Renewal',
    'lost-passport': 'Lost Passport',
    'stolen-passport': 'Stolen Passport',
    'new-passport': 'New Passport',
    'name-change': 'Name Change',
    'damaged-passport': 'Damaged Passport'
  }
  public video: Application|null = null
  public preview: Application|null = null
  public dialog: string = ''
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    private applicationService: ApplicationService,
    private changeDetectorRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    private router: Router
  ) {
    this.activatedRoute.pathFromRoot.forEach((route) => {
      this.params = {...this.params, ...route.snapshot.params}
    })
  }

  ngOnInit(): void {
    this.orderService.getOrderDetails(this.params['order_uuid'], 'travelers')
      .subscribe(response => {
        this.getTravelerDetails()
      })
  } 

  private getTravelerDetails(): void {
    this.orderService.getTravelerDetails(this.params['traveler_uuid'])
      .subscribe((response: Traveler) => {
        response.applications.forEach(application => {
          // this.checkApplicationStatus(application)

          if (application.product.type === 'passport') {
            let desired = this.applicationService.passportCheck(response.model, application.addons.includes('passport_card'), application.product.subtype)

            if (application.product.subtype !== desired) {
              application.product.desired = desired
              application.product.desired_label = this.passport_products[desired]
            }
          }
        })

        this.traveler = response
      })
  }

  public openApplicationPreview(application: Application): void {
    this.preview = application
    this.video = null
  }

  public closePreview(approved: boolean) {
    if (this.preview !== null) {
      if (approved) {
        this.applicationService.approvePacket(this.preview.uuid)
          .subscribe(response => {
            this.getTravelerDetails()
            if (this.preview) {
              if (this.preview?.manifest) {
                this.downloadApplication(this.preview)
              } else {
                // this.openShipDialog(this.preview)
              }
            }
           
            this.preview = null
          })
      } else {
        let filtered = this.traveler.applications.filter(application => this.preview && application.uuid === this.preview.uuid)[0]
  
        if (filtered) {
          filtered.status = 'ready'
          this.preview = null
        }
      }
    }

  }

  public messageCallback($event: any, application: Application): void {
    switch($event.action) {
      case 'refresh': 
        this.submitting = []
        this.getTravelerDetails()
        break
      case 'preview': 
        this.openApplicationPreview(application)
        break
      case 'submit':
        this.submitting.push(application.uuid)

        if (application.product.type === 'passport') {
          this.video = application
        }
        break
      case 'message':
        if (this.video && this.video.uuid === application.uuid) {
          this.message = $event.data.message
        }
        break
      case 'ship':
        this.openShipDialog(application)
        break
      case 'download': 
        this.downloadApplication(application)
        break
      case 'esignature':
        this.router.navigate(['esignature', this.params['order_uuid'], this.traveler.uuid, application.uuid, $event.document_uuid])
    }
  }

  private openShipDialog(application: Application) {
    let automanifest = ['passport-renewal', 'name-change', 'second-passport'].includes(application.product.subtype) && !application.promise_date
    let dialog = this.matDialog.open(ShipDateComponent, {
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
      panelClass: 'gwc-traveler-dialog-overlay',
      disableClose: true,
      data: {
        application, 
        traveler: this.traveler,
        step: automanifest ? 'pick' : 'done'
      }
    })
    
    dialog.afterClosed()
      .subscribe(response => {
        if (response) {
          this.getTravelerDetails()
        }
      })
  }

  public getButtonLabel(application: Application): string {
    if (application.progress >= 0 && application.progress < 100) return 'Continue Application'

    if (application.product.subtype === 'idp') {
      if (application.status !== 'completed' || !application.packet_approved) return 'Edit Application'
      else return "Submitted"
    }

    return 'Edit Application'
  }

  public downloadApplication(application: Application) {
    if (application.product.type === 'passport') {
      const reviewChecks: ReviewChecks = {
        product: application.product.subtype,
        gov_fee_included: application.gov_fee_included,
        adjudicated: ['smart_service_adj', 'expedited_service_adj'].includes(application.processing_service.slug)
      }
  
      const dialog = this.matDialog.open(DownloadPacketComponent, {
        ariaLabel: 'Download Packet Dialog',
        data: reviewChecks
      })
  
      dialog.afterClosed().subscribe(result => {
        if (result && result.accepted) {
          this.openDowload(application)
        }
      })
    } else {
      this.openDowload(application)
    }
  }

  public openDowload(application: Application) {
    let url = application.documents.length > 0 ? `${environment.API}application/${application.uuid}/user/download/document/${application.documents[0].uuid}` : `${environment.API}application/${application.uuid}/download`

    // if (this.orderService.active_domain === 'fedex') {
    //   url = `/pdfviewer/web/viewer.html?file=${url}%3Ftoken%3D456c766e-8253-4f59-b925-be7efaecd064`
    // }

    window.open(url, '_blank')
  }
}
