import { Component } from '@angular/core'
import { FieldTypeConfig } from '@ngx-formly/core'
import { FieldType } from '@ngx-formly/material'
import { selectOption } from 'src/types/wizard'

@Component({
  selector: 'gwc-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})

export class MultiSelectComponent extends FieldType<FieldTypeConfig> {
  public checkboxes!: selectOption[]

  ngOnInit(): void {
    this.checkboxes = this.props.options as Array<selectOption>
  }

  public toggleOption(option: selectOption): void {
    let list = this.formControl.getRawValue() as string[] || []
    let value = option.value
    let index = list.indexOf(value)

    if (index >= 0) {
      list.splice(index, 1)
    } else {
      list.push(value)
    }

    this.formControl.patchValue(list)
  }
}
