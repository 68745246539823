import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root'
})
export class BrowserCheckService {

	public isBrowserOutdated(): boolean {
		try {
			const missingFeatures =
				!('fetch' in window) ||
				!('Promise' in window) ||
				!('Map' in window) ||
				!('Set' in window) ||
				!('Intl' in window)

			return missingFeatures
		} catch (e) {
			// Error trying to execute basic modern js functions
			return true
		}
	}

	public isPhone(): boolean {
		try {
			let hasTouchScreen = false
			if ('maxTouchPoints' in navigator) {
				hasTouchScreen = navigator.maxTouchPoints > 0
			} else if ('msMaxTouchPoints' in navigator) {
				hasTouchScreen = (navigator as any).msMaxTouchPoints > 0
			} else {
				const mQ = matchMedia?.('(pointer:coarse)')
				if (mQ?.media === '(pointer:coarse)') {
					hasTouchScreen = !!mQ.matches
				} else if ('orientation' in window) {
					hasTouchScreen = true
				} else {
					// Only as a last resort, fall back to user agent sniffing
					const UA = (navigator as any).userAgent
					hasTouchScreen =
						/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
						/\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
				}
			}
			return hasTouchScreen
		} catch (e) {
			return false
		}
	}

}
