import { ChangeDetectorRef, Component, ElementRef, OnInit, signal, ViewChild, WritableSignal, Signal, computed } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { FieldType, FieldTypeConfig } from '@ngx-formly/core'
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper'
import { generateToken } from 'src/app/services/helper.service'
import { OrderService } from 'src/app/services/order.service'
import { ProfileService } from 'src/app/services/profile.service'
import { ScannerService } from 'src/app/services/scanner.service'
import { UserService } from 'src/app/services/user.service'
import { environment } from 'src/environments/environment'
import { QRData } from 'src/types/scanner'
import { User } from 'src/types/user'
import { Scan } from 'src/types/wizard'

@Component({
	selector: 'gwc-secure-scan',
	templateUrl: './secure.scan.component.html',
	styleUrls: ['./secure.scan.component.scss']
})

export class SecureScanComponent extends FieldType<FieldTypeConfig> implements OnInit {
	@ViewChild('input') file_input!: ElementRef

	public sendingText: WritableSignal<boolean> = signal(false)
	public uploadingImage: WritableSignal<boolean> = signal(false)
	public step: WritableSignal<string> = signal('')
	public is_photo: WritableSignal<boolean> = signal(false)
	public is_text: WritableSignal<boolean> = signal(false)
	public phone = new FormControl('', Validators.required)
	public documents: WritableSignal<Scan[]> = signal([])
	public scans: WritableSignal<Scan[]> = signal([])
	public listening: WritableSignal<string> = signal('')
	public completed: WritableSignal<string[]> = signal([])
	public uploading_file_index: WritableSignal<number> = signal(0)
	public croppedImage: Blob|null = null

	public uploadingDocument = computed(() => this.scans()[this.uploading_file_index()])

	public isCompleted = (document_uuid: string): Signal<boolean> => {
		return computed(() => this.completed().includes(document_uuid))
	}
	public qrLink: WritableSignal<string> = signal('')
	public firstScanIndex: number = 0
	private documentsSubscription!: NodeJS.Timeout
	public imageChangedEvent: WritableSignal<any|null> = signal(null)

	constructor(
		private scannerService: ScannerService,
		private userService: UserService,
		private orderService: OrderService,
		private ref: ChangeDetectorRef,
		private profileService: ProfileService
	) {
		super()
	}

	ngOnInit(): void {
		// let group: any = this.formControl

		// group.controls["drivers_license_front"].patchValue()
		// group.controls["drivers_license_back"].patchValue('')
		// delete this.model["drivers_license_front"] 
		// delete this.model["drivers_license_back"] 


		this.prefillPhone()
		this.getScans()
	}

	private prefillPhone(): void {
		this.userService.getUser()
			.subscribe((response: User) => {
				let phone = response.phones.filter(phone => phone.type === 'mobile')[0]

				if (phone && phone.value) {
					this.phone.patchValue(phone.value)
					this.phone.markAsTouched()
					this.phone.markAsDirty()
				}
			})
	}

	private getScans() {
		const docs = this.field.fieldGroup
		const value: any = this.formControl.value
		let index = 0
		let document_list: Scan[] = []
		let request_list: Scan[] = []
		let completed_list: string[] = []
		if (docs) {
			docs?.forEach(doc => {
				if (doc.key) {
					let key = doc.key.toString()

					if (key === 'online_passport_photo') {
						this.is_photo.set(true)
					}

					if (!value[key]) {
						request_list.push({
							document_uuid: '',
							friendly_name: doc.props?.['friendly_name'],
							type: key,
							message: doc.props?.['message']
						})
					} else {
						document_list.push({
							type: key,
							document_uuid: value[key],
							friendly_name: doc.props?.['friendly_name'],
							message: doc.props?.['message']
						})
						completed_list.push(value[key])
						index += 1
					}
				}
			})


			if (request_list.length > 0) {
				this.generateQRLink(request_list)
			}

			this.documents.set(document_list)
			this.scans.set(request_list)
			this.completed.set(completed_list)
			this.firstScanIndex = index
			this.step.set(request_list.length === 0 ? 'completed' : 'upload')
		}
	}

	private generateQRLink(documents: Scan[]) {
		const token: string = generateToken()
		const request_object: QRData = {
			token,
			domain: 'govworks',
			application_uuid: this.orderService.active_app,
			documents
		}

		this.scannerService.saveQRData(request_object)
			.subscribe({
				next: response => {
					this.documentsSubscription = setInterval(() => {
						this.scannerService.pollDemoDocuments(token)
							.subscribe({
								next: response => {
									if (response) {
										if (this.documentsSubscription) {
											clearInterval(this.documentsSubscription)
										}
			
										if (response.documents) {
											this.documents.set([...this.documents(), ...response.documents])
											this.step.set('phone')
											this.subscribeForDocumentUpdates(this.firstScanIndex)
										}
									}
								},
								error: error => {
								}
							})
					}, 3000)
			
					this.qrLink.set(`${environment.API}scanner/client/start/qr?token=${token}`)
				}
			})

	}

	public sendText() {
		if (this.scans().length > 0) {
			this.sendingText.set(true)
			this.scannerService.sendText(
				this.phone.value as string,
				this.scans(),
				this.model.profile_uuid,
				this.orderService.active_traveler,
				this.orderService.active_app)
				.subscribe({
					next: response => {
						this.is_text.set(true)
						this.documents.set([...this.documents(), ...response.documents])
						this.step.set('phone')
						this.subscribeForDocumentUpdates(this.firstScanIndex)
						this.sendingText.set(false)
						this.ref.detectChanges()
					},
					error: error => {
						this.sendingText.set(false)
					}
				})
		}
	}

	private subscribeForDocumentUpdates(index: number) {
		this.listening.set(this.documents()[index].document_uuid)

		let subscription = setInterval(() => {
			this.scannerService.getDocumentStatus(this.listening())
				.subscribe(response => {
					if (response.status === 'confirmed') {
						let group: any = this.formControl

						group.controls[response.type].patchValue(response.uuid)
						this.model[response.type] = response.uuid
						if (subscription) {
							clearInterval(subscription)
						}
						this.completed.set([...this.completed(), response.uuid])
						
						// Save the model when a photo is confirmed. If there are multiple photos, the model is saved after each photo is individually confirmed
						this.saveModel()
						if ((index + 1) < this.documents().length) {
							this.subscribeForDocumentUpdates(index + 1)
						} else {
							this.listening.set('')
							this.step.set('completed')
						}

						this.ref.detectChanges()
					}
				})
		}, 3000)
	}

	private saveModel(): void {
		this.profileService.saveProfileWithApplication(this.props['uuid'], this.model)
			.subscribe()
	}

	public uploadFile(index: number): void {
		if (this.documentsSubscription) {
			clearInterval(this.documentsSubscription)
		}
		this.step.set('crop')
		this.uploading_file_index.set(index)
	}

	public fileUploaded($event: any) {
		let file_list = this.file_input.nativeElement.files as FileList

		if (file_list.length > 0) {
			this.imageChangedEvent.set($event)
		}
	}

	public retakePhoto(document: Scan, index: number) {
		const request_list = [{ ...document, document_uuid: '' }]
		let new_documents: Scan[] = this.documents()
		new_documents.splice(index, 1)
		this.formControl.get(document.type)?.reset()
		this.documents.set(new_documents)
		this.scans.set(request_list)
		this.generateQRLink(request_list)
		this.step.set('upload')
		this.firstScanIndex = 0
	}

	public getDocumentImg(document_uuid: string): string {
		return `${environment.API}scanner/document/${document_uuid}/data`
	}

	public fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}

	public imageCropped(event: ImageCroppedEvent) {
		if(event.blob) {
			this.croppedImage = event.blob
		}
	}

	public uploadCroppedImage() {
		const index = this.uploading_file_index()
		const scans = this.scans()
		if (this.croppedImage && index !== null) {
			this.uploadingImage.set(true)
			this.scannerService.uploadFile('direct', this.croppedImage, undefined, scans[index], this.orderService.active_app)
      	.subscribe({
					next: response => {
						this.uploadingImage.set(false)

						if (response?.document_uuid) {
							const new_document: Scan = {
								...scans[index],
								document_uuid: response.document_uuid
							}
							const group: any = this.formControl

							group.controls[new_document.type].patchValue(response.document_uuid)
							this.model[new_document.type] = response.document_uuid
							this.completed.set([...this.completed(), response.document_uuid])
							this.documents.set([...this.documents(), new_document])
	
							if (this.scans()[index + 1]) {
								this.uploading_file_index.set(index + 1)
								this.imageChangedEvent.set(null)
							} else {
								this.imageChangedEvent.set(null)
								this.step.set('completed')
							}		
						}
					}
				})	
		}
	}

	imageLoaded(image: LoadedImage) {
		// show cropper
	}

	cropperReady() {
		// cropper ready
	}
	
	loadImageFailed() {
		// show message
	}

	// private dataURLtoFile(dataurl: string, filename: string): File {
	//   let arr = dataurl.split(',')
	//   let mime = arr[0].match(/:(.*?);/)?.[1]
	//   let bstr = Buffer.from(arr[1]).toString()
	//   let n = bstr.length
	//   let u8arr = new Uint8Array(n)

	//   while(n--){
	//     u8arr[n] = bstr.charCodeAt(n)
	//   }

	//   return new File([u8arr], filename, {type:mime})
	// }
}
