<section class="gwc-travelers">
	<div *ngFor="let traveler of travelers"
		class="gwc-travelers-item gwc-card">
		<div class="gwc-travelers-item__name">
			<img [src]="traveler.avatar" [alt]="traveler.friendly_name" />
			<h1>{{ traveler.friendly_name }}</h1>
		</div>
		<div class="gwc-travelers-item__details">
			<section>
				<div class="gwc-travelers-item__progress">
					<span>Application Progress</span>
					<span>{{ traveler.progress | number: '1.0-0' }}%</span>
				</div>
				<mat-progress-bar class="gwc-travelers-item__progress-bar"
					mode="determinate" 
					[value]="traveler.progress"></mat-progress-bar>
			</section>
			<gwc-button mat-flat-button
				*ngIf="traveler.status === 'new'; else continue"
				type="button"
				[disabled]="loading"
				[spinner]="loading"
				(callback)="getStarted(traveler.uuid)"
				class="gwc-travelers-item__btn"
				color="primary">
				Get Started
			</gwc-button>
			<ng-template #continue>
				<button mat-stroked-button
					[routerLink]="['traveler', traveler.uuid]"
					class="gwc-travelers-item__btn"
					color="primary">
					Continue Order
				</button>
			</ng-template>
		</div>
	</div>
</section>
