import { AngularSignaturePadModule, NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad'
import { Component, Inject, ViewChild } from '@angular/core'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs'
import { ButtonComponent } from 'src/app/components/button/button.component'
import { ApplicationService } from 'src/app/services/application.service'
import { SignaturePopUpResult, SignDocumentResponse } from 'src/types/esignature'

@Component({
	selector: 'gwc-signature',
	templateUrl: './signature.component.html',
	styleUrls: ['./signature.component.scss'],
	standalone: true,
	imports: [
		AngularSignaturePadModule,
    ButtonComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatTabsModule,
    ReactiveFormsModule
	]
})

export class SignatureComponent {
	@ViewChild('signature') public signaturePad!: SignaturePadComponent

	public signatureForm = this.formBuilder.group({
		name: [{value: this.data.traveler, disabled: true}, [Validators.required]],
		disclaimer: [false, [Validators.requiredTrue]],
		signature: ['']
	})
	public signaturePadOptions: NgSignaturePadOptions = {
		minWidth: 5,
		canvasWidth: 460,
		canvasHeight: 121,
		backgroundColor: 'transparent'
	}
	public currentStep: number = 0

	constructor(
		public formBuilder: FormBuilder,
		public dialogRef: MatDialogRef<SignatureComponent>,
		public applicationService: ApplicationService,
		@Inject(MAT_DIALOG_DATA) public data: {traveler: string, application_uuid: string, document_uuid: string}
	) {}

	public drawComplete(): void {
		this.signatureForm.controls.signature.patchValue(this.signaturePad.toDataURL())
	}

	public stepChanged($event: MatTabChangeEvent): void {
		this.currentStep = $event.index

		if ($event.index === 1) {
			this.signatureForm.controls.signature.addValidators(Validators.required)
		} else {
			this.signatureForm.controls.signature.removeValidators([Validators.required])
		}

		this.signatureForm.controls.signature.updateValueAndValidity()
	}

	public addSignature(): void {
		const value = this.signatureForm.getRawValue()
		let result: SignaturePopUpResult

		this.applicationService.signDocument(this.data.application_uuid, this.data.document_uuid)
			.subscribe({
				next: (response: SignDocumentResponse) => {
					result = response

					if (this.currentStep === 0 && value.name) {
						result.name = value.name
					} else if (value.signature) {
						result.signature = value.signature
					}

					this.dialogRef.close(result)
				}})
	}
}
