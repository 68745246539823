<section class="gwc-invoices">
	<div class="gwc-invoice"
		*ngFor="let invoice of invoices">
		<div class="gwc-invoice__label">
			<h2>
				{{ invoice.created_at | date:'MM/dd/yyyy' }} Invoice
			</h2>
			<div class="gwc-invoice__label-status">
				{{ invoice.status }}
			</div>
		</div>
		<div class="gwc-invoice__item"
			*ngFor="let item of invoice.items">
			<div>
				{{ item.details.friendly_name ? item.details.friendly_name : item.details.option ? item.details.option.label : item.details.label }}
			</div>
			<div>
				{{ item.amount/100 | currency }}
			</div>
		</div>
		<div class="gwc-invoice__item"
			*ngFor="let discount of invoice.discounts">
			<div>
				{{ discount.description }}
			</div>
			<div>
				{{ discount.amount/100 | currency }}
			</div>
		</div>
		<div class="gwc-invoice__total">
			<div>
				<strong>Amount Paid</strong>
			</div>
			<div>
				{{ invoice.amount/100 | currency }}
			</div>
		</div>
	</div>
</section>
