import { Component, OnInit } from '@angular/core'
import { FieldTypeConfig } from '@ngx-formly/core'
import { FieldType } from '@ngx-formly/material'

@Component({
  selector: 'gwc-social-security',
  templateUrl: './social.security.component.html',
  styleUrls: ['./social.security.component.scss']
})

export class SocialSecurityComponent  extends FieldType<FieldTypeConfig> implements OnInit {
  public show: boolean = false

  ngOnInit(): void {
  }

  public toggleShow(): void {
    this.show = !this.show
  }
}
