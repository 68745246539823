<div class="gwc-login__section">
	<h1>Account Log In</h1>
	@if (success) {
		<div class="gwc-forgot__success">
			Password reset link has been sent to your email address.
		</div>
	}
	<form [formGroup]="userService.loginForm" (ngSubmit)="reset()">
		<mat-form-field appearance="fill"
			[hideRequiredMarker]="true">
			<mat-label>Email</mat-label>
			<input matInput
				email
				type="email"
				autocomplete="username"
				pattern="^\w+([\-+._']\w+)*@\w+([\-._]\w+)*\.\w+([\-.]\w+)*$"
				formControlName="email">
			<mat-error>Please enter a valid email address</mat-error>
		</mat-form-field>
		<gwc-button color="primary"
			type="submit"
			[fullwidth]="true"
			[disabled]="submitting"
			[spinner]="submitting">
			Reset Your Password
		</gwc-button>
	</form>
	<a (click)="backToLogin()"
		class="gwc-loign__link">
		Back to login
	</a>
</div>
