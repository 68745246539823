import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { filter, Subscription } from 'rxjs'
import { OrderService } from 'src/app/services/order.service'
import { UserService } from 'src/app/services/user.service'
import { OrderSummary } from 'src/types/order'
import { User } from 'src/types/user'

@Component({
  selector: 'gwc-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit, OnDestroy {
  @Input() source: string|undefined
  @Input() phone: string|undefined
  @Input() order: OrderSummary|undefined

  public user!: User
  public breadcrumbs: any[] = []
  public breadcrumbs_subscription!: Subscription

  constructor(
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root)
      })
  }

  ngOnInit(): void {
    this.checkUser()
    this.breadcrumbs_subscription = this.orderService.breadcrumbLabels.subscribe(response => {
      this.updateBreadcrumbs(response)
    })
  }

  ngOnDestroy(): void {
    this.breadcrumbs_subscription.unsubscribe()
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: any[] = []): any[] {
    const children: ActivatedRoute[] = route.children

    if (children.length === 0) {
      return breadcrumbs
    }

    for (const child of children) {
      let snapshot = child.snapshot
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/')
      if (routeURL !== '') {
        url += `/${routeURL}`
      }

      if (snapshot.data['breadcrumb'] || snapshot.data['breadcrumbLabel']) {
        let breadcrumb: any = {url, label: snapshot.data['breadcrumbLabel']}

        if (snapshot.data['breadcrumb']) {
          breadcrumb.key = snapshot.params[snapshot.data['breadcrumb']]
          breadcrumb.label = this.orderService.breadcrumbLabels.value[breadcrumb.key ] || snapshot.data['breadcrumbLabel']
        }
  
        breadcrumbs.push(breadcrumb)
      }

      return this.createBreadcrumbs(child, url, breadcrumbs)
    }

    return []
  }

  private updateBreadcrumbs(labels: {[key: string]: string}) {
    this.breadcrumbs.forEach(item => {
      if (item.key && labels[item.key]) {
        item.label =  labels[item.key]
      }
    })
  }

  private checkUser(): void {
    this.userService.check()
      .subscribe((response: User) => {
        this.user = response
      })
  }

  public logout(): void {
    this.userService.logout()
      .subscribe(response => {
        this.router.navigate(['/login'])
      })
  }
}
