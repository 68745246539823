<form *ngIf="countries"
	[formGroup]="itineraryForm"
	(ngSubmit)="saveItinerary()">
	<div formArrayName="destinations">
		<div *ngFor="let destination of destinations.controls; index as i"
			class="gwc-itinerary-form"
			[formGroupName]="i">
			<!-- <mat-form-field class="gw-chkout-field gw-chkout-field--half gw-chkout-field--optional">
				<span id="selected-flag"
					class="flag-icon flag-icon-{{ itinerary.controls.country.value.value | lowercase }}">
				</span>
				<input matInput
					placeholder="Travel Destination"
					formControlName="country"
					[matAutocomplete]="auto">
				<mat-autocomplete #auto="matAutocomplete"
					[displayWith]="displayFn">
					<mat-option *ngFor="let country of filteredCountries | async" [value]="country">
						<span class="flag-icon flag-icon-{{ country.value | lowercase }}"></span>
						{{ country.label }}
					</mat-option>
				</mat-autocomplete>
				<mat-hint>Optional</mat-hint>
			</mat-form-field> -->
			<mat-form-field class="gwc-itinerary-form__field">
				<mat-label>Country</mat-label>
				<mat-select formControlName="country">
					<mat-option *ngFor="let destination of countries" 
						[value]="destination.label">
						{{ destination.label }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field class="gwc-itinerary-form__field">
			  <mat-label>Start Date</mat-label>
			  <input matInput 
			    mask="M0/d0/0000"
			    [leadZeroDateTime]="true"
			    [dropSpecialCharacters]="false"
					formControlName="start_date"
					(blur)="checkDate(destination, 'start_date')">
			  <input type="hidden" 
			    [value]="getDate(destination, 'start_date')"
			    (dateChange)="dateSelected($event, destination, 'start_date')"
					[min]="min"
					[max]="getDate(destination, 'end_date')"
			    [matDatepicker]="start_datepicker">
			  <mat-datepicker-toggle matSuffix 
			    [for]="start_datepicker">
			  </mat-datepicker-toggle>
			  <mat-datepicker #start_datepicker>
			  </mat-datepicker>
			  <mat-error>
			    {{ getErrorMessage(destination, 'start_date') }}
			  </mat-error>
			</mat-form-field>			
			<mat-form-field class="gwc-itinerary-form__field">
			  <mat-label>End Date</mat-label>
			  <input matInput 
			    mask="M0/d0/0000"
			    [leadZeroDateTime]="true"
			    [dropSpecialCharacters]="false"
					formControlName="end_date"
					(blur)="checkDate(destination, 'end_date')">
			  <input type="hidden" 
					[min]="getDate(destination, 'start_date') || min"
			    [value]="getDate(destination, 'end_date')"
			    (dateChange)="dateSelected($event, destination, 'end_date')"
			    [matDatepicker]="end_datepicker">
			  <mat-datepicker-toggle matSuffix 
			    [for]="end_datepicker">
			  </mat-datepicker-toggle>
			  <mat-datepicker #end_datepicker>
			  </mat-datepicker>
			  <mat-error>
			    {{ getErrorMessage(destination, 'end_date') }}
			  </mat-error>
			</mat-form-field>
			<button mat-icon-button
				*ngIf="destinations.controls.length > 1"
				class="gwc-itinerary-form__delete"
				(click)="deleteDestination(i)">
				<svg>
					<use xlink:href="assets/svg/shared.svg#delete" />
				</svg>
			</button>
		</div>
	</div>
	<div class="gwc-itinerary-form__btns">
		<gwc-button class="gwc-itinerary-form__btns-item"
			color="primary"
			type="button"
			[fullwidth]="true"
			(click)="addDestination()">
			Add Destination
		</gwc-button>
		<gwc-button class="gwc-itinerary-form__btns-item"
			color="primary"
			type="submit"
			[fullwidth]="true"
			[disabled]="submitting"
			[spinner]="submitting">
			Save
		</gwc-button>
	</div>
</form>
