import { KeyValue } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { AfterViewInit, ChangeDetectorRef, Component, NgIterable, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { FieldType, FieldTypeConfig } from '@ngx-formly/core'
import { Observable, of, Subscription } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { FacilityService } from 'src/app/services/facility.service'
import { OrderService } from 'src/app/services/order.service'
import { Facility } from 'src/types/wizard'

@Component({
  selector: 'gwc-location-finder',
  templateUrl: './facility.finder.component.html',
  styleUrls: ['./facility.finder.component.scss']
})

export class FacilityFinderComponent  extends FieldType<FieldTypeConfig> implements OnInit {
  public facilities: Facility[] = []
  public companies: {[key: string]: string} = {
    fedex: 'FedEx Office',
    cvs: 'CVS Pharmacy',
    walgreens: 'Walgreens',
    aaa: 'AAA'
  }
  public weekdays: {[key: string]: string} = {
    m: 'Mon',
		t: 'Tue',
		w: 'Wed',
		r: 'Thu',
    f: 'Fri',
		s: 'Sat',
		su: 'Sun',
  }
  public zipCodeControl = new FormControl('', Validators.required)
  public selected: Facility|undefined
  public originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0
  }
  public apiLoaded: Observable<boolean> = of(false)
  public mapOptions: google.maps.MapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    styles: [
      {
        stylers: [
          { saturation: -100 },
          { gamma: 1 }
        ]
      },
      {
        elementType: 'labels.text.stroke',
        stylers: [
          { visibility: 'off' }
        ]
      },
      {
        featureType: 'poi.business',
        elementType: 'labels.text',
        stylers: [
          { visibility: 'off' }
        ]
      },
      {
        featureType: 'poi.business',
        elementType: 'labels.icon',
        stylers: [
          { visibility: 'off' }
        ]
      },
      {
        featureType: 'poi.place_of_worship',
        elementType: 'labels.text',
        stylers: [
          { visibility: 'off' }
        ]
      },
      {
        featureType: 'poi.place_of_worship',
        elementType: 'labels.icon',
        stylers: [
          { visibility: 'off' }
        ]
      },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
          { visibility: 'simplified' }
        ]
      },
      {
        featureType: 'water',
        stylers: [
          { visibility: 'on' },
          { saturation: 50 },
          { gamma: 0 },
          { hue: '#50a5d1' }
        ]
      },
      {
        featureType: 'administrative.neighborhood',
        elementType: 'labels.text.fill',
        stylers: [
          { color: '#333333' }
        ]
      },
      {
        featureType: 'road.local',
        elementType: 'labels.text',
        stylers: [
          { weight: 0.5 },
          { color: '#333333' }
        ]
      },
      {
        featureType: 'transit.station',
        elementType: 'labels.icon',
        stylers: [
          { gamma: 1 },
          { saturation: 50 }
        ]
      }
    ]
  }
  public mapCenter!: google.maps.LatLngLiteral 
  private domain!: string
  private nextPageSubscription!: Subscription

  constructor(
    private facilityService: FacilityService,
    private ref: ChangeDetectorRef,
    private orderService: OrderService,
    httpClient: HttpClient,
  ){
    super()
    this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyDrBuO_eyjOF8o1vZ2bdbOsc3xW6_ALheo', 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      )
    this.domain = orderService.active_domain
  }

  ngOnInit(): void {
    // this.prefillZipCode()

    if (this.formControl.value && (this.formControl.value.facility || this.formControl.value.facility === null)) {
      this.formControl.patchValue(this.formControl.value.facility)
    }

    this.nextPageSubscription = this.orderService.nextPage.subscribe(response => {
      this.prefillZipCode()
    })
  }

  ngOnDestroy(): void {
    if (this.nextPageSubscription) {
      this.nextPageSubscription.unsubscribe()
    }
  }

  private prefillZipCode() {
    if (this.formControl.value) {
      this.zipCodeControl.patchValue(this.formControl.value.zip)
    } else {
      if (this.model.address?.zip) {
        this.zipCodeControl.patchValue(this.model.address.zip)
      }
    }

    this.getFacilities()
  }

  public getFacilities(): any {
    // this.formControl.reset()
    let zip_code = this.zipCodeControl.getRawValue()

    if (zip_code) {
      let brand = ['fedex', 'aaa'].includes(this.domain) && this.props['category'] === 'passport-photo' ? this.domain : null

      if (this.domain === 'idp') {
        brand = 'aaa'
      }

      return this.facilityService.getFacilities(zip_code, this.props['category'], brand)
      .subscribe(response => {
        this.facilities = response

        if (this.facilities[0] && (!this.formControl.value || !this.formControl.value.address)) {
          this.formControl.patchValue(this.facilities[0])
          this.mapCenter = {
            lat: this.facilities[0].latitude,
            lng: this.facilities[0].longitude
          }
        }

        this.ref.detectChanges()
        this.nextPageSubscription.unsubscribe()
      })
    }
  }

  public selectFacility(facility: Facility): void {
    this.formControl.patchValue(facility)
    this.mapCenter = {
      lat: facility.latitude,
      lng: facility.longitude
    }
  }

  public hoursAvailable = (day: any): boolean => {
    return this.formControl.value.hours[day.key]
  }
}
