import { animate, style, transition, trigger } from '@angular/animations'
import { Component } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatInputModule } from '@angular/material/input'
import { Router } from '@angular/router'
import { ButtonComponent } from 'src/app/components/button/button.component'
import { UserService } from 'src/app/services/user.service'

@Component({
  selector: 'gwc-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    MatInputModule,
    ReactiveFormsModule,
    MatButtonModule
  ],
  animations: [
    trigger('slideRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ])
    ]),
    trigger('slideLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ])
    ]),
  ]
})

export class SigninComponent {
  public error: boolean = false
  public submitting: boolean = false
  public error_message: string = ''
  public mode: string = 'password'
  public code_requested: boolean = false
  public initialLoad: boolean = true

  constructor(
    private router: Router,
    public userService: UserService
  ) {}

  public login(): void {
    if(this.userService.loginForm.valid) {
      this.submitting = true

      this.userService.logIn(this.userService.loginForm.getRawValue())
        .subscribe({
          next: (response) => {
            if (!response.uuid) {
              this.error = true
							
							if (response?.error?.message) {
								this.error_message = response.error.message
							} else {
								this.error_message =  this.mode === 'password' ? 'Invalid username or password.' : 'Invalid code.'
							}
            } else {
              this.router.navigate(['orders'])
            }

            this.submitting = false
          },
          error: () => {
            this.submitting = false
            this.error = true
            this.error_message = 'An error occurred. Please try again.'
          }
        })
    }
  }

  public forgotPassword(): void {
    this.userService.loginForm.markAsUntouched()
    this.router.navigate(['/forgot'])
  }

  public changeMode(mode: string): void {
		this.initialLoad = false
    this.error = false;
    this.error_message = ''
    this.submitting = false
		this.code_requested = false
		this.userService.loginForm.controls.password.reset()
    this.mode = mode
  }

  public requestCode(): void {
    this.error = false
    this.error_message = ''

    const emailControl = this.userService.loginForm.get('email')

    if (emailControl?.invalid) {
      return emailControl.markAsTouched()
    }

    this.submitting = true
    this.userService.requestLoginCode(emailControl!.value).subscribe({
      next: () => {
        this.code_requested = true
        this.submitting = false
      },
      error: () => {
        this.submitting = false
        this.error_message = 'Please enter a valid email address'
      }
    })
  }
}
