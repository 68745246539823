import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@Component({
  selector: 'gwc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
})

export class ButtonComponent {
  @Input() color!: string
  @Input() fullwidth!: boolean
  @Input() spinner!: boolean
  @Input() disabled!: boolean
  @Output() callback = new EventEmitter()

  public emitCallback(): void {
    if (this.callback) {
      this.callback.emit()
    }
  }
}
