<div class="gwc-btnselect"
	[class.gwc-btnselect--error]="formControl.touched && !formControl.valid"> 
	<button *ngFor="let button of buttons"
		class="gwc-btnselect__item"
		[class.gwc-btnselect__item--active]="formControl.value === button.value"
		(click)="selectOption(button)"
		type="button"
		mat-stroked-button>
		<div class="gwc-btnselect__item-content">
			<svg *ngIf="button.icon">
				<use [attr.xlink:href]="'assets/wizard/icons.svg#' + button.icon"/>
			</svg>
			{{ button.label }}
		</div>
	</button>
</div>
