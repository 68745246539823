import { ChangeDetectorRef, Component, ElementRef, HostListener, NgZone, ViewChild } from '@angular/core'
import { AbstractControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule, MatIconRegistry } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { FieldTypeConfig } from '@ngx-formly/core'
import { FieldType } from '@ngx-formly/material'
import { debounceTime, distinctUntilChanged, Observable, of, switchMap } from 'rxjs'
import { Address } from 'src/types/address'

@Component({
  selector: 'gwc-google-address',
  templateUrl: './google.address.component.html',
  styleUrls: ['./google.address.component.scss'],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatListModule,
    MatIconModule
  ]
})

export class GoogleAddressComponent extends FieldType<FieldTypeConfig> {
  @ViewChild('shippingAddressInput') shippingAddressInput!: ElementRef

  public previousValue: string = ''
  public predictions: google.maps.places.AutocompletePrediction[] = []
  public showPredictions: boolean = false

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    if (this.showPredictions) {
      const clickedInside = this.elementRef.nativeElement.contains(targetElement)
      if (!clickedInside) {
        this.showPredictions = false   
      }
    }
  }

  constructor(
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private elementRef: ElementRef
  ) {
    super()
    this.matIconRegistry.addSvgIcon('location',this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/svg/location.svg'))
  }

  ngAfterViewInit(): void {
    this.getPlaceAutocompleteService()
  }

  private getPlaceAutocompleteService(): void {
    const country = this.props['country'] || this.field.model?.country
    const options = {
      componentRestrictions: { country: country },
      types: ['address']
    }
    const autocompleteService = new google.maps.places.AutocompleteService()

    this.formControl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(inputValue => {
          this.showPredictions = true
          inputValue = inputValue.trim()
          if (inputValue.length > 0 && inputValue !== this.previousValue) {
            this.previousValue = inputValue
            return this.getPlacePredictions(inputValue, options, autocompleteService)
          } else {
            this.previousValue = inputValue
            return of([])
          }
        }))
      .subscribe((predictions: google.maps.places.AutocompletePrediction[]) => {
        this.ngZone.run(() => {
          this.predictions = predictions
          this.cdr.detectChanges()
        })
      })
  }

  private getPlacePredictions(inputValue: string, options: any, autocompleteService: google.maps.places.AutocompleteService): Observable<google.maps.places.AutocompletePrediction[]> {
    return new Observable(subscriber => {
      autocompleteService.getPlacePredictions(
        { input: inputValue, ...options },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
            subscriber.next(predictions)
          } else {
            subscriber.next([])
          }
          subscriber.complete()
        }
      )
    })
  }

  private updateAddressFields(prediction: google.maps.places.AutocompletePrediction): void {
    const placesService = new google.maps.places.PlacesService(this.shippingAddressInput.nativeElement)
    placesService.getDetails({ placeId: prediction.place_id }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && place?.address_components) {
        let address: Address = {}
        place.address_components.forEach((component) => {
          if (component.types.includes('street_number')) {
            address.address_1 = component.short_name
            address.number = component.short_name
          } else if (component.types.includes('route')) {
            address.address_1 = `${address.address_1} ${component.short_name}`
            address.street = component.short_name
          } else if (component.types.includes('locality')) {
            address.city = component.short_name
          } else if (!address.city && component.types.includes('sublocality_level_1') && component.short_name.length > 1) {
            address.city = component.short_name
          } else if (!address.city && component.types.includes('administrative_area_level_3') && component.short_name.length > 1) {
            address.city = component.short_name
          } else if (!address.city && component.types.includes('administrative_area_level_2') && component.short_name.length > 1) {
            address.city = component.short_name
          } else if (!address.city && component.types.includes('neighborhood') && component.short_name.length > 1) {
            address.city = component.short_name
          } else if (component.types.includes('administrative_area_level_1')) {
            address.state = component.short_name 
          } else if (component.types.includes('postal_code')) {
            address.zip = component.short_name
          }
        })
        const parentGroup = this.formControl.parent as FormGroup
        parentGroup.controls['place_id'].patchValue(place.place_id)
        parentGroup.patchValue(address)
      }
    })
  }

  public selectPrediction(prediction: google.maps.places.AutocompletePrediction): void {
    this.formControl.setValue(prediction.description)
    this.ngZone.run(() => {
      this.updateAddressFields(prediction)
      this.showPredictions = false
      this.cdr.detectChanges()
    })
  }

  public highlightMatch(text: string, query: string): SafeHtml {
    if (!query) {
      return this.domSanitizer.bypassSecurityTrustHtml(text)
    }
    const regex = new RegExp(`(${query})`, 'gi')
    const highlightedText = text.replace(regex, '<strong>$1</strong>')
    return this.domSanitizer.bypassSecurityTrustHtml(highlightedText)
  } 

  public hidePredictions(): void {
    const parentGroup = this.formControl.parent as FormGroup

    if (!parentGroup.controls['place_id'].value) {
      this.formControl.setErrors({selected: true})
    }
  }
}
