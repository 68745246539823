import { Component, OnInit } from '@angular/core'
import { ProfileService } from 'src/app/services/profile.service'
import { CardProfileComponent } from './card/card-profile.component'
import { Profile } from 'src/types/user'

@Component({
  selector: 'gwc-profiles',
  standalone: true,
  imports: [
    CardProfileComponent
  ],
  templateUrl: './profiles.component.html',
  styleUrl: './profiles.component.scss'
})

export class ProfilesComponent implements OnInit {
  public profiles!: Profile[]

  constructor(
    private profileService: ProfileService
  ) {}

  ngOnInit(): void {
    this.getProfiles()
  }

  private getProfiles(): void {
    this.profileService.getProfiles().subscribe({
      next: (response: Profile[]) => {
        this.profiles = response
      },
      error: () => {
        this.profiles = []
      }
    })
  }
}
