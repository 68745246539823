import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomeComponent } from './home.component'
import { InboxComponent } from './inbox/inbox.component'
import { MessageComponent } from './message/message.component'
import { OrdersComponent } from './orders/orders.component'
import { ProfilesComponent } from './profiles/profiles.component'

const homeRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'orders',
        component: OrdersComponent
      },
      {
        path: 'profiles',
        component: ProfilesComponent
      },
      {
        path: 'inbox',
        component: InboxComponent
      },
      {
        path: 'inbox/:message_uuid',
        component: MessageComponent
      },
      {
        path: '',
        redirectTo: '/orders',
        pathMatch: 'full'
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(homeRoutes)],
  exports: [RouterModule]
})

export class HomeRoutingModule { }
