import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { StartOrderComponent } from 'src/app/dialogs/start.order/start.order.component'
import { OrderService } from 'src/app/services/order.service'
import { Order } from 'src/types/order'

@Component({
  selector: 'gwc-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})

export class OrdersComponent implements OnInit {
  public orders: Order[] = []

  constructor(
    private router: Router,
    private orderService: OrderService,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getOrders()
  }

  private getOrders(): void {
    this.orderService.getOrders()
      .subscribe((response: Order[]) => {
        this.orders = response
      })
  }

  public startOrder(uuid: string): void {
    const dialog = this.matDialog.open(StartOrderComponent, {
      data: { trip_name: '' },
      ariaLabel: 'Start Order Dialog'
    })
    
    dialog.afterClosed().subscribe(result => {
      if (result) {
        let data: any = {}

        if (result.trip_name) {
          data.label = result.trip_name
        }
  
        this.orderService.updateOrder(uuid, data)
          .subscribe(response => {
            this.router.navigate(['/order', uuid])
          })
      }
    })
  }
}
