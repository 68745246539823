import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EsignDisclaimerComponent } from './esign.disclaimer.component'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatButtonModule } from '@angular/material/button'
import { ReactiveFormsModule } from '@angular/forms'

@NgModule({
  declarations: [
    EsignDisclaimerComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule
  ]
})
export class EsignDisclaimerModule { }
