<div class="gwc-dialog gwc-download-packet">
  <gwc-dialog-header label="Download Your Application Packet"></gwc-dialog-header>
  <section class="gwc-dialog__body">
    <div class="gwc-download-packet__title">
      <h2>
        FINAL REVIEW INSTRUCTIONS
      </h2>
      <p>
        It is now time to conduct a final review of your application. Failure to follow these commonly missed instructions will result in delays in securing your US Passport.
      </p>
    </div>
    <form [formGroup]="reviewForm" (ngSubmit)="downloadDocuments()">
      <div class="gwc-download-packet__form">
        <section>
          <mat-checkbox formControlName="ds_application"
            class="gwc-download-packet__form-checkbox"
            color="primary">
            <strong>DS Application</strong>
          </mat-checkbox>
          <ul>
            <li>The DS application must be printed out single sided.</li>
            <li *ngIf="['passport-renewal', 'name-change', 'second-passport'].includes(data.product)">
              DO NOT forget to sign and date your application in black ink only.
            </li>
            <li *ngIf="['passport-renewal', 'name-change', 'second-passport'].includes(data.product)">
              The expired passport must be included with your application.
            </li>
          </ul>
        </section>
    
        <section *ngIf="!data.gov_fee_included">
          <mat-checkbox formControlName="government_fees"
            class="gwc-download-packet__form-checkbox"
            color="primary">
            <strong>Government Fees</strong>
          </mat-checkbox>
          <ul>
            <li>You must include a check or money order for the cost of the US Passport.</li>
            <li>This cost is called the government fees.</li>
            <li>The check or money order is payable to “US Department of State”.</li>
          </ul>
        </section>
    
        <section>
          <mat-checkbox formControlName="passport_photo"
            class="gwc-download-packet__form-checkbox"
            color="primary">
            <strong>Passport Photo - Inspect the Photo One Last Time</strong>
          </mat-checkbox>
          <ul>
            <li>Make sure you are not wearing glasses.</li>
            <li>The lighting must be even, with no obscuring shadows or glare.</li>
            <li>The photo must have a plain white background.</li>
          </ul>
        </section>

        <section *ngIf="data.adjudicated">
          <mat-checkbox formControlName="no_inbound_shipping"
            class="gwc-download-packet__form-checkbox"
            color="primary">
            <strong>This service does not require an inbound shipping label</strong>
          </mat-checkbox>
          <ul>
            <li>Please follow the below instructions for submitting your completed application and supporting requirements to the Acceptance Agent.</li>
          </ul>
        </section>
    
        <div *ngIf="!['passport-renewal', 'name-change', 'second-passport'].includes(data.product)" 
          class="gwc-download-packet__aa">  
          <svg>
            <use href="assets/svg/shared.svg#icon-seal" />
          </svg>
          <div>
            <h4>
              Visit your nearest Acceptance Agent.
            </h4>
            <p *ngIf="!data.adjudicated">
              <b>IMPORTANT!</b> Before shipping your documents to us, you must first have them reviewed and sealed by an Acceptance Agent. Please locate your nearest acceptance facility <a href="https://iafdb.travel.state.gov/" target="_blank" alt="AA">here</a>. Once sealed, you must then use the included FedEx shipping label to ship your envelope to us for processing.
            </p>
            <p *ngIf="data.adjudicated">
              <b>IMPORTANT!</b> Please ensure to submit your completed Application package to the Acceptance Agency within 30 days of download. This will ensure you receive timely updates on the status of your passport request.
            </p>
          </div>
        </div>
      </div>
    
      <div class="gwc-download-packet__agree">
        <mat-checkbox formControlName="final_check"
          class="gwc-download-packet__agree-checkbox"
          color="primary">
          <p>
            By downloading this document, I am agreeing to the Terms of Use and Privacy Policy. Additionally, I have reviewed the above final review instructions.
          </p>
        </mat-checkbox>
      </div>
      
      <div mat-dialog-actions
        class="gwc-download-packet__actions">
        <gwc-button class="gwc-download-packet__download"
          color="primary"
          [fullwidth]="true"
          [disabled]="!allChecks()"
          aria-label="Okay, Download"
          type="submit">
          Okay, Download
        </gwc-button>
      </div>
    </form>
  </section>
</div>
