import { Component, NgZone } from '@angular/core'
import { AbstractControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms'
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker'
import { FieldTypeConfig, FormlyConfig } from '@ngx-formly/core'
import { FieldType } from '@ngx-formly/material'
import { DateTime } from 'luxon'
import { DateValidator } from 'src/types/wizard'
import { MatFormFieldModule } from '@angular/material/form-field'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { MatInputModule } from '@angular/material/input'
import * as _ from 'lodash'

@Component({
	selector: 'gwc-datepicker',
	templateUrl: './datepicker.component.html',
	styleUrls: ['./datepicker.component.scss'],
	standalone: true, 
	imports: [
		FormsModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatInputModule,
		NgxMaskDirective,
		NgxMaskPipe, 
		ReactiveFormsModule
	],
	providers: [
		provideNgxMask()
	]
})

export class DatepickerComponent extends FieldType<FieldTypeConfig> {
	public messages: {[key: string]: string} = {}

	ngOnInit() {
		// Some old models have the value wrapped in a value key
		if (this.formControl.value?.value) {
			this.formControl.patchValue(this.formControl.value.value)
		}
	}

	
	public dateSelected($event: MatDatepickerInputEvent<DateTime, any>): void {
		this.formControl.patchValue($event.value?.toFormat('MM/dd/yyyy'))
	}

	public getDate(): DateTime | null {
		if (this.formControl.value) {
			return DateTime.fromFormat(this.formControl.value, 'MM/dd/yyyy')
		}

		return null
	}

	public getErrorMessage(): string {
		if (this.formControl.errors) {
			const messages = this.field.validation?.messages
			const errors = this.formControl.errors
			const errors_list = Object.keys(errors)

			if (messages?.[errors_list[0]]) {
				return messages?.[errors_list[0]] as string
			} 
		}

		return 'Please enter a valid date(MM/DD/YYYY).'
	}
}
