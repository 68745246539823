<div class="gwc-login__section">
	<h1>Reset Your Password</h1>
	<p>Your password must be at least 8 characters and use a combination of letters, numbers, and symbols.</p>
	<form [formGroup]="resetForm" 
		(ngSubmit)="reset()">
		<mat-form-field appearance="fill"
			[hideRequiredMarker]="true">
			<mat-label>Email</mat-label>
			<input matInput
				email
				type="email"
				autocomplete="username"
				formControlName="email"
				pattern="^\w+([\-+._']\w+)*@\w+([\-._]\w+)*\.\w+([\-.]\w+)*$">
			<mat-error>Please enter a valid email address.</mat-error>
		</mat-form-field>
		<mat-form-field appearance="fill"
			[hideRequiredMarker]="true">
			<mat-label>New Password</mat-label>
			<input matInput
				type="password"
				autocomplete="current-password"
				formControlName="password">
			<mat-error>
				@if (resetForm.controls.password.errors?.['required']) {
					A new password is required.
				} @else if (resetForm.controls.password.errors?.['minlength']) {
					You must enter 8 characters or more.
				} @else if (resetForm.controls.password.errors?.['pattern']) {
					Please use letters, numbers, and symbols.
				}
			</mat-error>
		</mat-form-field>
		<mat-form-field appearance="fill"
			[hideRequiredMarker]="true">
			<mat-label>Confirm Password</mat-label>
			<input matInput
				type="password"
				[errorStateMatcher]="passwordErrorStateMatcher"
				autocomplete="current-password"
				formControlName="password_confirmation">
			<mat-error>
				@if (resetForm.controls.password_confirmation.errors) {
					Please confirm your new password.
				} @else if (resetForm.errors) {
					The passwords must match.
				}
			</mat-error>
		</mat-form-field>
		<gwc-button color="primary"
			type="submit"
			[fullwidth]="true"
			[disabled]="submitting"
			[spinner]="submitting">
			Update Your Password
		</gwc-button>
	</form>
</div>
