import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ForgotComponent } from './containers/login/forgot/forgot.component'
import { LoginComponent } from './containers/login/login.component'
import { ResetComponent } from './containers/login/reset/reset.component'
import { SetupComponent } from './containers/login/setup/setup.component'
import { SigninComponent } from './containers/login/signin/signin.component'
import { EsignatureComponent } from './containers/esignature/esignature.component'

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    children: [
      {
        path: 'login',
        component: SigninComponent
      },
      {
        path: 'forgot',
        component: ForgotComponent
      },
      {
        path: 'setup',
        component: SetupComponent
      },
      {
        path: 'reset/:token',
        component: ResetComponent
      }
    ]
  },
  {
    path: 'scanner',
    loadChildren: () => import('./containers/scanner/scanner.module').then(m => m.ScannerModule),
    data: {
      fullScreen: true
    }
  },
  {
    path: 'review',
    loadChildren: () => import('./containers/review/review.module').then(m => m.ReviewModule),
    data: {
      fullScreen: true
    }
  },
  {
    path: 'esignature/:order_uuid/:traveler_uuid/:application_uuid/:document_uuid',
    component: EsignatureComponent,
    data: {
      fullScreen: true
    }
  },
  {
    path: 'refund-request',
    loadChildren: () => import('./containers/refund.exception/refund.exception.module').then(m => m.RefundExceptionModule),
    data: {
      fullScreen: true
    }
  },
  {
    path: '**',
    redirectTo: '/login'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, 
    {
      paramsInheritanceStrategy: 'always'
    }
  )],
  exports: [RouterModule]
})

export class AppRoutingModule { }
