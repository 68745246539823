import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HomeRoutingModule } from './home-routing.module'
import { HomeComponent } from './home.component'
import { LayoutModule } from 'src/app/components/layout/layout.module'
import { OrdersComponent } from './orders/orders.component'
import { InboxComponent } from './inbox/inbox.component'
import { MessageComponent } from './message/message.component'
import { MatButtonModule } from '@angular/material/button'
import { StartOrderComponent } from 'src/app/dialogs/start.order/start.order.component'

@NgModule({
  declarations: [
    HomeComponent,
    OrdersComponent,
    InboxComponent,
    MessageComponent
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    LayoutModule,
    MatButtonModule,
    StartOrderComponent
  ]
})

export class HomeModule { }
