<div class="gwc-login__section">
	@if (mode === 'password') {
		<h1>Account Log In</h1>
		
		@if (error) {
			<div class="gwc-signin__error">
				{{ error_message }}
			</div>
		}

		<div @slideRight 
			[@.disabled]="initialLoad">
			<form [formGroup]="userService.loginForm" (ngSubmit)="login()">
				<mat-form-field appearance="fill"
					[hideRequiredMarker]="true">
					<mat-label>Email</mat-label>
					<input matInput
						email
						type="email"
						autocomplete="username"
						formControlName="email"
						pattern="^\w+([\-+._']\w+)*@\w+([\-._]\w+)*\.\w+([\-.]\w+)*$">
					<mat-error>Please enter a valid email address</mat-error>
				</mat-form-field>
				<mat-form-field appearance="fill"
					[hideRequiredMarker]="true">
					<mat-label>Password</mat-label>
					<input matInput
						type="password"
						autocomplete="current-password"
						formControlName="password">
					<mat-error>Please enter your password</mat-error>
				</mat-form-field>
				<gwc-button color="primary"
					type="submit"
					[fullwidth]="true"
					[disabled]="submitting"
					[spinner]="submitting">
					Log In
				</gwc-button>
				<div class="gwc-login__code-request">
					<span class="gwc-login__code-request-text">or</span>
					<button mat-button class="gwc-login__code-request-btn"
						color="primary"
						(click)="changeMode('code')">
						Email Me a One-Time Code
					</button>
				</div>
			</form>
			<a (click)="forgotPassword()"
				class="gwc-loign__link">
				Forgot your password?
			</a>
		</div>
	} @else if (mode === 'code') {
		<div @slideLeft>
			<span class="gw-chkout-login__title">
				{{ code_requested ? 'Please check your email for the temporary login code.' : 'Please verify your email to receive a temporary login code.' }}
			</span>
			<form [formGroup]="userService.loginForm" (ngSubmit)="login()">
				@if (error) {
					<div class="gwc-signin__error">
						{{ error_message}}
					</div>
				}

				@if (!code_requested) {
					<mat-form-field appearance="fill"
						[hideRequiredMarker]="true">
						<mat-label>Email</mat-label>
						<input matInput
							email
							type="email"
							autocomplete="username"
							formControlName="email"
							pattern="^\w+([\-+._']\w+)*@\w+([\-._]\w+)*\.\w+([\-.]\w+)*$">
					</mat-form-field>
					<gwc-button color="primary"
						type="submit"
						[fullwidth]="true"
						[disabled]="submitting"
						[spinner]="submitting"
						(callback)="requestCode()">
						Request a Code
					</gwc-button>
				} @else {
					<div class="gw-chkout-form">
						<mat-form-field class="gw-chkout-field"
							[hideRequiredMarker]="true">
							<mat-label>Login Code</mat-label>
							<input matInput
								placeholder="Login Code"
								type="text"
								formControlName="password">
							<mat-error>
								Please enter the code.
							</mat-error>
						</mat-form-field>
					</div>
					<gwc-button color="primary"
						[disabled]="submitting"
						type="submit"
						[spinner]="submitting"
						[fullwidth]="true">
						Log In
					</gwc-button>
				}
				<a class="gwc-chkout-login__forgot gwc-chkout-login__forgot--back"
					(click)="changeMode('password')">
					<svg width="8px" height="8px" xmlns="http://www.w3.org/2000/svg"><path d="M8 3.5H1.915L4.71.705 4 0 0 4l4 4 .705-.705L1.915 4.5H8z" fill="currentColor" fill-rule="evenodd"/></svg>
					Login with a password.
				</a>
			</form>
		</div>
	}
</div>
