import { Injectable } from '@angular/core'
import { Scan } from 'src/types/wizard'
import { ApiService } from './api.service'
import { QRData } from 'src/types/scanner'

@Injectable({
  providedIn: 'root'
})

export class ScannerService extends ApiService {
  public sendText(phone: string, documents: Scan[], profile_uuid: string, traveler_uuid: string, application_uuid: string) {
    return this.postRequest(`scanner/client/start`, {
      domain: 'govworks',
      phone,
      profile_uuid,
      traveler_uuid,
      documents,
      application_uuid
    })
  }

  public saveQRData(data: QRData) {
    return this.postRequest(`scanner/client/saveQr`, data)
  }

  public pollDemoDocuments(token: string) {
    return this.postRequest(`demo/photo/documents`, {token})
  }
  
  public getScanData(token: string) {
    return this.getRequest(`scanner/open/${token}`)
  }

  public uploadFile(document_uuid: string, file: File|Blob, plugin?: string, document?: Scan, application_uuid?: string) {
    let fd = new FormData()
    
    if (file instanceof File) {
      fd.append('file', file)
    } else {
      fd.append('file', file, 'uploadedFile')
    }

    if (plugin) {
      fd.append('plugin', plugin)
    }

    if (document) {
      fd.append('document', JSON.stringify(document))
    }

    if (application_uuid) {
      fd.append('application_uuid', application_uuid)
    }

    return this.postRequest(`scanner/document/${document_uuid}/upload`, fd)
  }

  public confirmDocument(document_uuid: string) {
    return this.postRequest(`scanner/document/${document_uuid}/confirm`, {})
  }

  public getDocumentStatus(document_uuid: string) {
    return this.getRequest(`scanner/client/document/${document_uuid}/info`)
  }
}
