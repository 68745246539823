<div class="form-row" *ngFor="let group of field.fieldGroup; let i = index; let last=last">
	<div class="gwc-multiple__item">
		<formly-field class="col" [field]="group"></formly-field>
		<button mat-icon-button
			*ngIf="formControl.value.length > 1"
			type="button"
			class="gwc-multiple__item-remove"
			(click)="remove(i)">
			<svg>
				<use href="assets/svg/shared.svg#close" />
			</svg>
		</button>
	</div>
</div>
<button mat-button
	color="primary"
	class="gwc-multiple__btn"
	(click)="add()"
	type="button">
	<div class="gwc-multiple__btn-label">
		<span class="gwc-multiple__btn-icon">+</span>
		Add
	</div>
</button>
