import { FormControl, FormGroupDirective, NgForm } from "@angular/forms"
import { ErrorStateMatcher } from "@angular/material/core"

export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const mismatch = form?.errors?.['mismatch']

    return !!(control && (control.invalid || (control.touched && mismatch)) && (control.dirty || control.touched))
  }
}
