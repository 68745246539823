<div class="gwa-dialog-header"
	[ngClass]="{ 'gwa-dialog-header--dark': darkBackground,
		'gwa-dialog-header--shadow': shadow }">
	<div>{{ label }}</div>
	<button *ngIf="close"
		[mat-dialog-close]
		class="gwa-dialog-header__close"
		mat-icon-button
		aria-label="Close Dialog"
		tabindex="-1">
		<svg>
			<use href="assets/svg/shared.svg#close" />
		</svg>
	</button>
</div>
