import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { OrderRoutingModule } from './order-routing.module'
import { OrderComponent } from './order.component'
import { FormComponent } from './traveler/form/form.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FormlyMaterialModule } from '@ngx-formly/material'
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core'
import { LayoutModule } from 'src/app/components/layout/layout.module'
import { TravelersComponent } from './order.layout/travelers/travelers.component'
import { ButtonSelectComponent } from './traveler/form/button.select/button.select.component'
import { DatepickerComponent } from './traveler/form/datepicker/datepicker.component'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { NgxMaskPipe, NgxMaskDirective, provideNgxMask } from 'ngx-mask'
import { MultipleComponent } from './traveler/form/multiple/multiple.component'
import { MaskedInputComponent } from './traveler/form/masked.input/masked.input.component'
import { LocationService } from 'src/app/services/location.service'
import { StepperComponent } from './traveler/form/stepper/stepper.component'
import { ItineraryComponent } from './order.layout/itinerary/itinerary.component'
import { InvoicesComponent } from './order.layout/invoices/invoices.component'
import { FacilityFinderComponent } from './traveler/form/facility.finder/facility.finder.component'
import { SocialSecurityComponent } from './traveler/form/social.security/social.security.component'
import { GoogleMapsModule } from '@angular/google-maps'
import { PhonePipeModule } from 'src/pipes/phone.pipe/phone.pipe.module'
import { FilterPipeModule } from 'src/pipes/filter.pipe/filter.pipe.module'
import { MultiSelectComponent } from './traveler/form/multi-select/multi-select.component'
import { DownloadPacketModule } from 'src/app/dialogs/download.packet/download.packet.module'
import { SecureScanComponent } from './traveler/form/secure.scan/secure.scan.component'
import { ImageCropperModule } from 'ngx-image-cropper'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatSelectModule } from '@angular/material/select'
import { CheckboxComponent } from './traveler/form/checkbox/checkbox.component'
import { ShipDateComponent } from 'src/app/dialogs/ship.date/ship.date.component'
import { ButtonComponent } from 'src/app/components/button/button.component'
import { AutocompleteComponent } from './traveler/form/autocomplete/autocomplete.component'
import { ADDRESS_AUTOCOMPLETE, ADDRESS_COUNTRY_AUTOCOMPLETE,COUNTRY_OPTIONS, STATE_PROVINCE_LIST } from 'src/data/address.form'
import { FORM_TO_COMBO, buildDates } from 'src/data/form'
import { POBoxboxValidator, decimalValidator, futureValidator, validDate } from 'src/data/validators'
import _ from 'lodash'
import { GoogleAddressComponent } from './traveler/form/google.address/google.address.component'
import { EMPLOYMENT_HISTORY_ENTRY } from 'src/data/employment.form'
import { QRCodeModule } from 'angularx-qrcode'

@NgModule({
  declarations: [
    OrderComponent,
    FormComponent,
    TravelersComponent,
    ButtonSelectComponent,
    MultipleComponent,
    MaskedInputComponent,
    StepperComponent,
    ItineraryComponent,
    InvoicesComponent,
    FacilityFinderComponent,
    SocialSecurityComponent,
    MultiSelectComponent,
    SecureScanComponent,
    CheckboxComponent
  ],
  providers: [
    LocationService,
    provideNgxMask()
  ],
  imports: [
    CommonModule,
    FormlyMaterialModule,
    NgxMaskPipe, 
    NgxMaskDirective,
    FormlyModule.forRoot({
      types: [
        {
          name: 'name-field', 
          extends: 'input',
          defaultOptions: {
            props: {
              pattern: "^[A-Za-z]+(?:[ \\-]?[A-Za-z']+)*$",
              maxLength: 35
            },
            validation: {
              messages: {
                pattern: "Please use only letters, spaces, dashes and apostrophes. No contiguous dashes/apostrophes."
              }
            }
          }
        },
        { name: 'buttons', component: ButtonSelectComponent },
        { name: 'datepicker', 
          component: DatepickerComponent,
          defaultOptions: {
            expressions: {
              'props.minDate': (field: FormlyFieldConfig) => {
                return buildDates(field, 'min')
              },
              'props.maxDate': (field: FormlyFieldConfig) => {
                return buildDates(field, 'max')
              }
            },
            validators: {
              validation: ['valid-date']
            }
          }
        },
        { name: 'multiple', component: MultipleComponent },
        { name: 'masked-input', component: MaskedInputComponent },
        { name: 'stepper', component: StepperComponent },
        { name: 'social-security-input', component: SocialSecurityComponent },
        { name: 'location-finder', component: FacilityFinderComponent },
        { name: 'multi-select', component: MultiSelectComponent },
        { name: 'autocomplete', component: AutocompleteComponent },
        { name: 'google-address', component: GoogleAddressComponent },
        {
          name: 'country-autocomplete',
          extends: 'autocomplete',
          defaultOptions: {
            props: {
              required: true,
              label: 'Country',
              options: COUNTRY_OPTIONS
            }
          }
        },
        { name: 'secure-scanner', component: SecureScanComponent },
        { name: 'gwa-checkbox', component: CheckboxComponent },
        {
          name: 'mapped-select',
          extends: 'select',
          defaultOptions: {
            expressions: {
              'props.options': (field: FormlyFieldConfig) => {
                const parent: string = _.at(field.model, field.props?.['parentKey'])[0] as string
                let options = [...(field.props?.['mappedOptions']?.[parent] || [])]

                if (field.props?.['enableNone']) {
                  options.unshift({
                    label: 'None',
                    value: ''
                  })
                }

                return options
              },
              'hide': (field: FormlyFieldConfig) => {
                const parent: string = _.at(field.model, field.props?.['parentKey'])[0] as string

                return !(field.props?.['mappedOptions'][parent])
              }
            }
          }
        },
        {
          name: 'state-province-autocomplete',
          extends: 'autocomplete',
          defaultOptions: {
            props: {
              mappedOptions: STATE_PROVINCE_LIST,
            },
            expressions: {
              'props.label': (field: FormlyFieldConfig) => {
                const parent: string = field.props?.['parentKey'] ? _.at(field.model, field.props?.['parentKey'])[0] as string : field.props?.['parentValue']

                return parent === 'US' ? 'State' : 'Province'
              },
              'props.options': (field: FormlyFieldConfig) => {
                const parent: string = field.props?.['parentKey'] ? _.at(field.model, field.props?.['parentKey'])[0] as string : field.props?.['parentValue']

                return field.props?.['mappedOptions'][parent]
              },
              'hide': (field: FormlyFieldConfig) => {
                if (field.props?.['parentDetailKey']) {
                  const detail = _.at(field.model, field.props?.['parentDetailKey'])[0]

                  if (detail) {
                    return true
                  }
                }
                
                const parent: string = field.props?.['parentKey'] ? _.at(field.model, field.props?.['parentKey'])[0] as string : field.props?.['parentValue']

                return !(field.props?.['mappedOptions'][parent])
              }
            }
          }
        },
        {
          name: 'address-autocomplete',
          extends: 'formly-group',
          defaultOptions: {
            fieldGroup: ADDRESS_AUTOCOMPLETE,
            expressions: {
              'hide': (field: FormlyFieldConfig) => {
                const country = field.model?.country || field.props?.['country']
                return country ? false : true
              }
            }
          },
        },
        {
          name:'address-country-autocomplete',
          extends: 'formly-group',
          defaultOptions: {
            fieldGroup: ADDRESS_COUNTRY_AUTOCOMPLETE
          }
        },
        {
          name: 'employment-history',
          extends: 'formly-group',
          defaultOptions: {

          }
        },
        {
          name: 'from-to',
          extends: 'formly-group',
          defaultOptions: {
            fieldGroup: FORM_TO_COMBO,
            fieldGroupClassName: "gwc-form__field-group"
          }
        },
        {
          name: 'employment-history-entry',
          extends: 'formly-group',
          defaultOptions: {
            fieldGroup: EMPLOYMENT_HISTORY_ENTRY,
            fieldGroupClassName: "gwc-form__field-group"
          }
        },
      ],
      validationMessages: [
        { name: 'required', message: 'This field is required.' },
        { name: 'decimal', message: 'You must enter a round number.' },
        { name: 'pobox', message: 'P.O. Box addresses cannot be used on this form.' },
        { name: 'future', message: (error: any, field: FormlyFieldConfig) => {
          return `${field.props?.label} cannot be in the future.`
        }}
      ],
      validators: [
        { name: 'decimal', validation: decimalValidator },
        { name: 'pobox', validation: POBoxboxValidator },
        { name: 'valid-date', validation: validDate},
        { name: 'future', validation: futureValidator}
      ],
      extras: {
        checkExpressionOn: 'modelChange',
        resetFieldOnHide: false
      },
    }),
    FormsModule,
    GoogleMapsModule,
    ImageCropperModule,
    LayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    OrderRoutingModule,
    ReactiveFormsModule,
    PhonePipeModule,
    FilterPipeModule,
    MatCheckboxModule,
    ButtonComponent,
    DownloadPacketModule,
    MatProgressSpinnerModule,
    ShipDateComponent,
    QRCodeModule
  ]
})

export class OrderModule {
}
