import { Component, OnInit } from '@angular/core'
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { ActivatedRoute } from '@angular/router'
import { DateTime } from 'luxon'
import { LocationService } from 'src/app/services/location.service'
import { Location } from 'src/types/location'
import { OrderService } from 'src/app/services/order.service'
import { Itinerary } from 'src/types/order'
import { from, Observable, toArray } from 'rxjs'
import * as _ from 'lodash'

@Component({
  selector: 'gwc-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./itinerary.component.scss']
})

export class ItineraryComponent implements OnInit {
  private order_uuid!: string
  public submitting: boolean = false
  public countries: Location[] = []
  // public countries: Observable<Location>|undefined
  public filteredCountries: Observable<Location[]>|undefined
  public min: DateTime = DateTime.now()
  public itineraryForm: FormGroup = this.formBuilder.group({
    destinations: this.formBuilder.array([])
  })
  public destinationForm = this.formBuilder.group({
    country: [''],
    start_date: [''],
    end_date: ['']
  })
  public itinerary: Itinerary|undefined

  constructor(
    private formBuilder: FormBuilder,
    private orderService: OrderService,
    private activatedRoute: ActivatedRoute,
    private locationService: LocationService
  ) {
    this.order_uuid = this.activatedRoute.parent?.snapshot.params['order_uuid']
  }

  ngOnInit(): void {
    this.getItinerary()
    this.getCountries()
  }

  get destinations() {
    return this.itineraryForm.controls['destinations'] as FormArray<any>
  }

  private getCountries() {
    this.locationService.getCountries()
      .subscribe( res => {
        this.countries = res
        // this.countries = from(res)

        // this.filteredCountries = this.countries
        //   .pipe(
        //     toArray()
        //   )
      })
  }

  private getItinerary(): void {
    this.orderService.getOrderDetails(this.order_uuid, 'itinerary')
      .subscribe((response: Itinerary) => {
        this.itinerary = response

        if (response.destinations.length > 0) {
          response.destinations.forEach(destination => {
            let destinationFormCopy = _.cloneDeep(this.destinationForm)
            let destinationValue = {
              country: destination.country,
              start_date: destination.start_date ? DateTime.fromFormat(destination.start_date, 'yyyy-MM-dd').toFormat('MM/dd/yyyy') : null,
              end_date: destination.end_date ? DateTime.fromFormat(destination.end_date, 'yyyy-MM-dd').toFormat('MM/dd/yyyy') : null
            }
  
            destinationFormCopy.patchValue(destinationValue)
            this.destinations.controls.push(destinationFormCopy)
          })
        } else {
          let destinationFormCopy = _.cloneDeep(this.destinationForm)
          this.destinations.controls.push(destinationFormCopy)
        }
      })
  }

  public getDate(group: AbstractControl, key: string): DateTime | null {
    let control = (group as FormGroup).controls[key]

    if (control && control.value) {
      return DateTime.fromFormat(control.value, 'MM/dd/yyyy')
    }

    return null
  }

  public dateSelected($event: MatDatepickerInputEvent<DateTime, any>, group: AbstractControl, key: string): void {
    let control = (group as FormGroup).controls[key]

    if (control) {
      control.patchValue($event.value?.toFormat('MM/dd/yyyy'))
    }
  }

  public deleteDestination(i: number) {
    this.destinations.removeAt(i)
  }

  public addDestination() {
    let destinationFormCopy = _.cloneDeep(this.destinationForm)
    this.destinations.push(destinationFormCopy)
  }

  public displayFn(item: Location) {
    return item ? item.label : ''
  }

  public getDestination(group: AbstractControl) {
    return (group as FormGroup).controls['country'] as FormControl
  }

  public saveItinerary() {
    if (this.destinations.controls.every(item => item.valid) && this.itinerary) {
      let data = {
        itinerary: {
          itinerary_uuid: this.itinerary.uuid,
          ...this.itineraryForm.getRawValue() 
        }
      }

      this.orderService.updateOrder(this.order_uuid, data)
        .subscribe(response => {
          this.orderService.itineraryUpdated.next()
        })
    }
  }

  public checkDate(destination: any, type: string) {
    let control = destination.controls[type] as FormControl

    if (control.valid) {
      if (type === 'start_date') {
        let start_date = DateTime.fromFormat(control.value, 'MM/dd/yyyy')
  
        if (start_date < this.min) {
          control.setErrors({min: true})
        } else {
          let end_date_control = destination.controls.end_date as FormControl

          if (end_date_control.valid) {
            let end_date = DateTime.fromFormat(end_date_control.value, 'MM/dd/yyyy')

            if (start_date > end_date) {
              control.setErrors({end_date: true})
            }
          }
        }
      } else {
        let end_date = DateTime.fromFormat(control.value, 'MM/dd/yyyy')
        let start_date_control = destination.controls.start_date as FormControl

        if (start_date_control.valid) {
          let start_date = DateTime.fromFormat(start_date_control.value, 'MM/dd/yyyy')

          if (start_date > end_date) {
            control.setErrors({start_date: true})
          }
        }
      }
    }
  }

  public getErrorMessage(destination: any, type: string): string {
    let control = destination.controls[type] as FormControl

    if (type === 'start_date') {
      if (control.hasError('min')) {
        return 'Travel date cannot be in the past.'
      } else if (control.hasError('end_date')) {
        return 'Start Date cannot be after End Date.'
      }
    } else {
      if (control.hasError('start_date')) {
        return 'End Date cannot be before Start Date.'
      }
    }

    return 'Please enter a valid date.'
  }
}
