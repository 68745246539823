import { Component } from '@angular/core'
import { FieldTypeConfig } from '@ngx-formly/core'
import { FieldType } from '@ngx-formly/material'

@Component({
  selector: 'gwc-masked-input',
  templateUrl: './masked.input.component.html',
  styleUrls: ['./masked.input.component.scss']
})

export class MaskedInputComponent extends FieldType<FieldTypeConfig> {
  ngOnInit() {
  }
}
