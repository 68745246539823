<button mat-flat-button 
  class="gwc-button"
  (click)="emitCallback()"
	[color]="color"
  [disabled]="disabled"
  [class.gwc-button--loading]="spinner"
  [class.gwc-button--fullwidth]="fullwidth">
  <mat-spinner class="gwc-button__spinner"
    diameter="20">
	</mat-spinner>
	<ng-content></ng-content>
</button>
