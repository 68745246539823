<div class="gwc-form" *ngIf="application && model">
	<form class="gwc-form__main"
		[class.gwc-form__main--hidden]="false"
		[formGroup]="form">
		<formly-form [model]="model"
			[form]="form"
			[fields]="application.steps"
		></formly-form>
	</form>
	<!-- <form class="gwc-form__main"
		[class.gwc-form__main--hidden]="app_uuid !== 'fjbnfkldbnfd'"
		[formGroup]="form">
		<formly-form [model]="model"
			[form]="form"
			[fields]="steps_idp"
		></formly-form>
	</form> -->
</div>
