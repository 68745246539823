import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { OrderService } from 'src/app/services/order.service'
import { Invoice } from 'src/types/order'

@Component({
  selector: 'gwc-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})

export class InvoicesComponent implements OnInit {
  public invoices!: Invoice[]
  public order_uuid!: string

  constructor(
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService
  ) {
    if (this.activatedRoute.parent) {
      this.order_uuid = this.activatedRoute.parent.snapshot.paramMap.get('order_uuid') || ''
    }
  }

  ngOnInit(): void {
    this.orderService.getOrderDetails(this.order_uuid, 'invoices')
      .subscribe(response => {
        this.invoices = response
      })
  }

}
