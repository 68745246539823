<section class="gwc-inbox">
	<header class="gwc-content-header">
		<h1>Message Center</h1>
	</header>
	<div *ngIf="messages && messages.length === 0"
		class="gwc-card gwc-inbox__empty">
		<img src="assets/mail/img-inbox-empty.png"/>
		<span>You have no messages...</span>
	</div>
	<table *ngIf="messages && messages.length > 0"
		class="gwc-inbox__msgs">
		<thead>
			<tr>
				<th>From</th>
				<th>Subject</th>
				<th>Traveler</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let message of messages"
				class="gwc-inbox__msg"
				[class.gwc-inbox__msg--new]="!message.read_at">
				<td>
					<div class="gwc-inbox__msg-from">
						{{ message.from }} <br/>
						<span>{{ message.created_at | date: 'MM/dd/yyyy hh:mm a' }}</span>
					</div>
				</td>
				<td>
					{{ message.subject }}
				</td>
				<td>
					<div class="gwc-inbox__msg-traveler">
						<img [src]="message.traveler.avatar" />
						<div>
							{{ message.traveler.name }}
							<span>{{ message.order.order_number }}</span> 
						</div>
					</div>
				</td>
				<td>
					<a mat-flat-button
						[routerLink]="[message.uuid]"
						class="gwc-inbox__btn">
						View
				</a>
				</td>
			</tr>
		</tbody>
	</table>
</section>
