<section class="gwc-itinerary">
	<h2>Itinerary</h2>
	@if (itinerary && itinerary.destinations.length > 0 && itinerary.destinations[0].country) {
		<div class="gwc-itinerary__main">
			@for (destination of itinerary.destinations; track destination) {
				<div>
					<div class="gwc-itinerary__country">
						@if (destination.formatted) {
							<span class="fi fi-{{ destination.formatted.value.toLowerCase() }}"></span>
						}
						{{ destination.formatted ? destination.formatted.label : destination.country }}
					</div>
					<div>{{ destination.start_date | date: 'MM/dd/yyyy' }}
						@if (destination.end_date) {
							- {{ destination.end_date | date: 'MM/dd/yyyy' }}
						}
					</div>
				</div>
			}
		</div>
	} @else {
		<div class="gwc-itinerary__empty">
			<img src="assets/order/itinerary.svg"/>
			<div>Information about your trip is needed.</div>
			<button mat-button
				[routerLink]="['/order', order_uuid, 'itinerary']"
				color="primary">
				Edit itinerary
			</button>
		</div>
	}
</section>
