import { Component, ElementRef, ViewChild } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { FieldTypeConfig } from '@ngx-formly/core'
import { FieldType } from '@ngx-formly/material'
import { selectOption } from 'src/types/wizard'

@Component({
  selector: 'gwc-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ]
})

export class AutocompleteComponent extends FieldType<FieldTypeConfig> {
  @ViewChild('autocomplete_input') input!: ElementRef<HTMLInputElement>

  public filteredOptions: selectOption[] = []
  public filteredCommonlyUsed: selectOption[] = []
  private select_options: selectOption[] = []

  ngOnInit(): void {
    this.resetFilteredOptions()
    this.required
  }

  public resetFilteredOptions() {
    this.select_options =  this.props.options as selectOption[] || []
    this.filteredOptions = this.select_options
    this.filteredCommonlyUsed = this.props['commonly_used'] || []
  }

  public displayFunction = (value: string): string => {
    if (this.select_options) {
      const filtered = this.select_options.filter(options => options.value === value)

      return filtered[0]?.label || ''
    }
    
    return ''
  }

  public filterOptions(): void {
    if (this.input) {
      const filterValue = this.input.nativeElement.value.toLowerCase()
      this.filteredOptions = this.select_options.filter(option => option.label.toLowerCase().startsWith(filterValue))

      if (this.props['commonly_used']) {
        if (filterValue) {
          this.filteredCommonlyUsed = []
        } else {
          this.filteredCommonlyUsed = this.props['commonly_used']
        }
      }
    }
  }
}
