import { Component } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { Router } from '@angular/router'
import { ButtonComponent } from 'src/app/components/button/button.component'
import { UserService } from 'src/app/services/user.service'

@Component({
  selector: 'gwc-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    MatInputModule,
    ReactiveFormsModule
  ]
})

export class ForgotComponent {
  public success: boolean = false
  public submitting: boolean = false

  constructor(
    public userService: UserService,
    private router: Router
  ) { }

  public reset() {
    let email = this.userService.loginForm.controls.email

    if (email.valid) {
      this.submitting = true

      this.userService.sendPasswordReset(email.value)
        .subscribe(response => {
          this.success = true
          this.submitting = false
        })
    }
  }

  public backToLogin(): void {
    this.userService.loginForm.markAsUntouched()
    this.router.navigate(['/login'])
  }
}
