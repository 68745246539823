import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HttpClientModule } from '@angular/common/http'
import { HomeModule } from './containers/home/home.module'
import { OrderModule } from './containers/order/order.module'
import { MatLuxonDateModule } from '@angular/material-luxon-adapter'
import { ButtonComponent } from './components/button/button.component'

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ButtonComponent,
    HomeModule, 
    HttpClientModule,
    MatLuxonDateModule,
    OrderModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})

export class AppModule { }
