import { Component, OnInit } from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import { UserService } from 'src/app/services/user.service'

@Component({
  selector: 'gwc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    RouterModule
  ]
})

export class LoginComponent implements OnInit {
  public setup: boolean = false

  constructor(
    private userService: UserService,
    private router: Router 
  ) { }

  ngOnInit(): void {
    this.userCheck()
    this.setup = this.router.routerState.snapshot.url === '/setup'
  }

  private userCheck(): void {
    this.userService.check()
      .subscribe(response => {
        if (response.uuid) {
          if (this.setup) {
            if(!response.new) {
              this.router.navigate(['/orders'])
            }
          } else {
            if(response.new) {
              return this.router.navigate(['/setup'])
            }
            
            return this.router.navigate(['/orders'])
          }
        }

        return
      })
  }
}
