import { Injectable } from '@angular/core'
import { DateTime } from 'luxon'
import { map, Observable } from 'rxjs'
import { Application, Traveler } from 'src/types/traveler'
import { idp } from '../containers/order/traveler/form/idp'
import { ApiService } from './api.service'
import { of } from "rxjs";
import { first } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class ApplicationService extends ApiService {
  public getApplication(traveler_uuid: string, app_uuid: string) {
    return this.getRequest(`traveler/${traveler_uuid}/compiled`)
      .pipe(
        map((response: Traveler) => {
          let new_response: any = {}
          new_response.model = response.model
          let filtered = response.applications.filter(item => item.uuid === app_uuid)

          if (filtered[0]) {
            new_response.model = response.model
            new_response.application = filtered[0] 
          }

          return new_response
        })
      )
  }

  public saveProgress(app_uuid: string, progress: number) {
    return this.postRequest(`application/${app_uuid}/progress`, {progress})
  }

  public getStatus(application_uuid: string) {
    return this.getRequest(`application/${application_uuid}/current/status3`)
  }

  public getProduct(app: Application) {
    return this.postRequest(`product/filterAttribute`, {
      citizenship:'US',
      residency: 'FL',
      domain: app.domain,
      residence_country: 'US',
      type: 'passport',
      subtype: app.product.desired,
      country: 'US'
    })
  }

  public changeProduct(app_uuid: string, product_uuid: string) {
    return this.postRequest(`application/${app_uuid}/change/product`, {product_uuid})
  }

  public getLuxonDate(date: string): DateTime {
    return DateTime.fromFormat(date, 'MM/dd/yyyy')
  }

  public submitIDPApplication(app_uuid: string) {
    return this.postRequest(`application/${app_uuid}/idp`, {})
  }

  public submitApplication(app_uuid: string) {
    return this.postRequest(`application/${app_uuid}/process`, {})
  }

  public downloadApplicationDocument(app_uuid: string, doc_uuid: string) {
    return this.getRequest(`application/${app_uuid}/user/download/document/${doc_uuid}`)
  }

  public passportCheck(model: any, passportCard: boolean = false, current: string): string {
    try {
      const issue_limit: DateTime = DateTime.now().minus({years: 15})

      if (model['date_of_birth'] && DateTime.now().minus({years: 16}) < this.getLuxonDate(model['date_of_birth'])) {
        // If applicant is a minor they have to apply for a child passport
        return 'child-passport'
      }
  
      if (model['issued_passports'] === 'book') {
        // If applicant has been issued a passport book before
        if (model['passport']?.in_possession === 'yes') {
          // If applicant has previous book in their possession
          if (model['passport'].issue_date && model['date_of_birth'] &&
            this.getLuxonDate(model['passport'].issue_date).minus({years: 16}) < this.getLuxonDate(model['date_of_birth'])) {
            // If applicant's previous book was a child passport they have to apply for a new passport
            return 'new-passport'
          }
  
          if (model['passport'].issue_date && issue_limit > this.getLuxonDate(model['passport'].issue_date)) {
            // If applicant's previous passport book was issued more
            // than 15 years ago thay have to apply for a new passport
            return 'new-passport'
          }
  
          if (model['passport'].is_damaged === 'yes') {
            // If applicant's previous passport book is damaged
            // they have to apply for a damaged passport
            return 'damaged-passport'
          }
  
          // If applicant's previous book is not damaged
          if (model['name_change_passport']?.book === 'yes') {
            // If applican has had a name change since their previous passport
            // book was issued they have to apply for a name change passport
            return 'name-change'
  
          }
  
          // If applicant hasn't had a name change since their previous passport
          // book was issued thay can apply for a passport renewal
          return current === 'second-passport' ? current : 'passport-renewal'
        }
  
        if (['lost', 'stolen'].includes(model['passport'].in_possession)) {
          // If applicant's previous passport book was lost
          if (model['passport'].not_in_possession?.issue_date_available === 'no') {
            // If applicant is not able to provide an issue date of the previous passport
            if (model['passport'].not_in_possession.issue_date === 'yes') {
              // If applicant's previous passport book was issued more than
              // 15 years ago they have to apply for a new passport
              return 'new-passport'
            }
  
            // If applicant's previous passport book was issued less
            // than 15 years ago they have to apply for a lost passport
            return model['passport'].in_possession === 'stolen' ? 'stolen-passport' : 'lost-passport'
          }
  
          if (issue_limit < this.getLuxonDate(model['passport'].issue_date)) {
            // If applicant's previous passport book was issued less
            // than 15 years ago they have to apply for a lost passport
            return model['passport'].in_possession === 'stolen' ? 'stolen-passport' : 'lost-passport'
  
          }
  
          // If applicant's previous passport book was issued more
          // than 15 years ago they have to apply for a new passport
          return 'new-passport'
        }
      } else if (model['issued_passports'] === 'card') {
        // If applicant has been issued a passport card before
        if (model['passport_card']?.in_possession === 'yes') {
  
          // If applicant has previous passport card in their possession
          if (model['passport_card'].issue_date) {
            if (model['date_of_birth'] &&
              this.getLuxonDate(model['passport_card'].issue_date).minus({years: 16}) < this.getLuxonDate(model['date_of_birth'])) {
              // If applicant's previous card was a child passport they have to apply for a new passport
              return 'new-passport'
             }

             if (issue_limit > this.getLuxonDate(model['passport_card'].issue_date)) {
                // If applicant's previous passport card was issued more
                // than 15 years ago thay have to apply for a new passport
                return 'new-passport'
              }
          }
  
          // If applican't previous card wasn't a child passport
          if (model['passport_card'].is_damaged === 'yes') {
            // If applican't previous passport card was damaged
            // they have to apply for a damaged passport
            return 'damaged-passport'
          }
  
          // If applican't previous card wasn't damaged
          if (model['name_change_passport'].card === 'yes') {
            // If applican has had a name change since their previous passport card
            // was issued they have to apply for a name change passport
            return 'name-change'
          }
  
          // If applicant hasn't had a name change since their previous passport card
          // was issued thay can apply for a passport renewal
          return current === 'second-passport' ? current : 'passport-renewal'
        }
  
        if (model['passport_card'].in_possession === 'lost') {
          // If applican's previous passport card was lost they have to apply for a lost passport
          return 'lost-passport'
        }
  
        if (model['passport_card'].in_possession === 'stolen') {
          // If applicant's previous passport card was stolen they have to apply for a stolen passport
          return 'stolen-passport'
        }
  
      } else if (model['issued_passports'] === 'both' && model['passport'] && model['passport_card']) {
        if (model['passport']?.issue_date && issue_limit > this.getLuxonDate(model['passport'].issue_date)
          && model['passport_card']?.issue_date && issue_limit > this.getLuxonDate(model['passport_card'].issue_date)) {
            return 'new-passport'
        }

        // If applicant has been issued a passport book and a passport card before
        if (model['passport'].in_possession === 'lost') {
          // If applicant's previous passport book was lost
          if (model['passport_card'].in_possession === 'lost') {
            // If applicant's passport card was lost
            return 'lost-passport'
          } else if (model['passport_card'].in_possession === 'stolen') {
            // If applicant's passport card was stolen
            return 'stolen-passport'
          }
  
          if (model['passport'].not_in_possession?.issue_date_available === 'yes') {
            // If applicant can provide issue date of the passport book
            if (model['passport'].issue_date 
              && issue_limit < this.getLuxonDate(model['passport'].issue_date)) {
              // If applicant's previous passport book was issued less than 15 years ago
              // they have to apply for a lost passport
              return 'lost-passport'
            }
  
            // If applicant's previous passport book was issued more than 15 years ago
            // they have to apply for a new passport
            return 'new-passport'
          }
  
          // If applicant can't provide issue date of the passport book
          if (model['passport'].not_in_possession?.issue_date === 'yes') {
            // If applicant's previous passport book was issued more than 15 years ago
            // they have to apply for a new passport
            return 'new-passport'
          }
  
          // If applicant's previous passport book was issued less than 15 years ago
          // or applicant doesn't know when lost passport book was issued
          // they have to apply for a lost passport
          return 'lost-passport'
        }
  
        if (model['passport'].in_possession === 'stolen') {
          // If applicant's previous passport book was stolen
          if (model['passport_card'].in_possession === 'lost') {
            // If applicant's passport card was lost
            return 'lost-passport'
          }
  
          if (model['passport_card'].in_possession === 'stolen') {
            // If applicant's passport card was stolen
            return 'stolen-passport'
          }
  
          if (model['passport'].not_in_possession?.issue_date_available === 'yes') {
  
            // If applicant can provide issue date of the passport book
            if (model['passport']?.issue_date && issue_limit < this.getLuxonDate(model['passport'].issue_date)) {
              // If applicant's previous passport book was issued less than 15 years ago
              // they have to apply for a stolen passport
              return 'stolen-passport'
            }
  
            // If applicant's previous passport book was issued more than 15 years ago
            // they have to apply for a new passport
            return 'new-passport'
          }
  
          // If applicant can't provide issue date of the passport book
          if (model['passport'].not_in_possession.issue_date === 'yes') {
            // If applicant's previous passport book was issued more than 15 years ago
            // they have to apply for a new passport
            return 'new-passport'
          }
  
          // If applicant's previous passport book was issued less than 15 years ago
          // or applicant doesn't know when lost passport book was issued
          // they have to apply for a stolen passport
          return 'stolen-passport'
        }
  
        if (model['passport_card'].in_possession === 'lost' && passportCard) {
  

          // If applicant's previous passport card was lost
          // and they want to replace it
          // they have to apply for a lost passport
          return 'lost-passport'
        }
  
        if (model['passport_card'].in_possession === 'stolen' && passportCard) {
  
          // If applicant's previous passport card was stolen
          // and they want to replace it
          // they have to apply for a stolen passport
          return 'stolen-passport'
        }
  
        // If applican has their previous passport book and passport card in their possesion
        if (model['passport'].is_damaged === 'no') {
  
          // If applicant's previous passport is not damaged
          if (model['passport_card'].in_possession === 'yes'
            && model['passport_card'].is_damaged === 'no') {
  
            // If applicant's previous passport card is not damaged
            if (model['passport'].issue_date && issue_limit < this.getLuxonDate(model['passport'].issue_date)) {
              // If applicant's previous passport was issued less than 15 years ago
              if (model['passport'].issue_date && model['date_of_birth'] 
                && this.getLuxonDate(model['passport'].issue_date).minus({years: 16}) < this.getLuxonDate(model['date_of_birth'])) {
                // If applicant's previous passport book was a child pasport
                if (model['passport_card']?.issue_date
                  && this.getLuxonDate(model['passport_card']?.issue_date).minus({years: 16}) < this.getLuxonDate(model['date_of_birth'])) {
                  // If applicant's previous passport card was a child passport
                  // they have to apply for a new passport
                  return 'new-passport'
                }
  
                // If applicant's previous passport card wasn't a child passport
                // they can apply for a passport renewal
                if (model['name_change_passport']?.card === 'yes') {
                  // If applicant has had a name change since previous passport card was issued
                  return 'name-change'
                }
  
                // If applicant hasn't had a name change since previous passport card was issued
                return current === 'second-passport' ? current : 'passport-renewal'
              }
  
              // If applicant's previous passport book wasn't a child passport
              // they can apply for a passport renewal
              if (model['name_change_passport']?.card === 'yes' ||
                model['name_change_passport']?.book === 'yes') {
                // If applicant has had a name change since previous passport
                // book and/or passport card were issued
                return 'name-change'
              }
  
              // If applicant hasn't had a name change since previous passport
              // book and passport card was issued
              return current === 'second-passport' ? current : 'passport-renewal'
            }
  
            // If applicant's previous passport was issued more than 15 years ago
            if (model['passport_card']?.issue_date && model['date_of_birth'] && 
              (this.getLuxonDate(model['passport_card'].issue_date).minus({years: 16}) < this.getLuxonDate(model['date_of_birth'])) || issue_limit > this.getLuxonDate(model['passport_card'].issue_date)) {
              // If applicant's previous passport card was a child passport
              // they have to apply for a new passport
              return 'new-passport'
            }

  
            // If applicant's previous passport card wasn't a child passport
            // they can apply for a passport renewal
            return current === 'second-passport' ? current : 'passport-renewal'
          }
  
          // If applicant's previous passport card was damaged
          if (model['passport']?.issue_date && model['date_of_birth'] 
            && issue_limit < this.getLuxonDate(model['passport'].issue_date)
            && this.getLuxonDate(model['passport'].issue_date).minus({years: 16}) > this.getLuxonDate(model['date_of_birth'])) {
            // If applican't previous passport book wasn't a child passport
            // and was issued less than 15 years ago
            if (model['name_change_passport']?.book === 'yes') {
              // If applicant has had a name change since previous passport book was issued
              return 'name-change'
            }
  
            // If applicant hasn't had a name change since previous passport book was issued
            return current === 'second-passport' ? current : 'passport-renewal'
          }
  
          if (model['passport_card']?.in_possession === 'lost') {
            // If applicant's previous passport card was lost
            return 'lost-passport'
          }
  
          if (model['passport_card']?.in_possession === 'stolen') {
            // If applicant's previous passport card was lost
            return 'stolen-passport'
          }
  
          // If applicant's previous passport book was a child passport or was
          // issued more than 15 years ago they have to apply for a new passport
          return 'new-passport'
        }
  
        // if (model.passport_card.is_damaged.value === 'no') {
  
        //   // If applicant's previous passport card is not damaged
        //   if (moment(model.passport_card.issue_date.value)
        //     .subtract(16, 'years')
        //     .isBefore(model.date_of_birth.value)) {
  
        //     // If applicant's previous passport card was a child passport
        //     // they have to apply for a new passport
        //     return 'new-passport';
  
        //   } else {
  
        //     // If applicant's previous passport card wasn't a child passport
        //     // they can apply for a passport renewal
        //     if (model.name_change_passport.card.value === 'yes') {
  
        //       // If applicant has had a name change since previous passport card was issued
        //       return 'name-change';
  
        //     } else {
  
        //       // If applicant hasn't had a name change since previous passport card was issued
        //       return 'passport-renewal';
  
        //     }
        //   }
        // } else {
  
        //   // If applicant's previous passport card was damaged
        //   return 'new-passport';
  
        // }
  
        return 'damaged-passport'
      } else if (model['issued_passports']) {
        // If applicant hasn't been issued a passport book
        // or a passport card before they have to apply for a new passport
        return 'new-passport'
      }
  
      return current
    } catch (e) {
      return current
    }
  }

  public approvePacket(app_uuid: string) {
    return this.postRequest(`application/${app_uuid}/approvalStatus`, {packet_approved: true})
  }

  public setPromiseDate(application_uuid: string, date: string, raw: string) {    
    return this.postRequest(`application/${application_uuid}/promiseDate/${date}`, {raw})

    /*
    POST 
    https://api2.dev.govworks.com/api/application/c74f1665-99db-476b-9a47-5b93f82800fd/promiseDate/2023-02-10 
    500

    message: Http failure response for 
    https://api2.dev.govworks.com/api/application/c74f1665-99db-476b-9a47-5b93f82800fd/promiseDate/2023-02-10: 
    500 OK

    status: 500
    */
  }

  public signDocument(application_uuid: string, document_uuid: string) {
    return this.postRequest(`application/document/signDocument`, {application_uuid, document_uuid})
  }

  public finishSigning(application_uuid: string, document_uuid: string) {
    return this.postRequest(`application/document/approveSignatures`, {application_uuid, document_uuid})
  }
}
