import { ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HttpClientModule } from '@angular/common/http'
import { HomeModule } from './containers/home/home.module'
import { OrderModule } from './containers/order/order.module'
import { MatLuxonDateModule } from '@angular/material-luxon-adapter'
import { ButtonComponent } from './components/button/button.component'
import { environment } from 'src/environments/environment'
import Bugsnag from '@bugsnag/js'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'

// configure Bugsnag ASAP
if (environment.production) {
  Bugsnag.start({ apiKey: '548a5ddb7a78076a0d2761c7e047784d', appVersion: '1.0.0' })
  BugsnagPerformance.start({ apiKey: '548a5ddb7a78076a0d2761c7e047784d' })
}

const providers = environment.production ? [ { provide: ErrorHandler, useFactory: errorHandlerFactory } ] : []

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ButtonComponent,
    HomeModule, 
    HttpClientModule,
    MatLuxonDateModule,
    OrderModule,
    AppRoutingModule
  ],
  providers: providers,
  bootstrap: [AppComponent]
})

export class AppModule { }
