@switch (step()) {
	@case ('upload') {
		<div class="gwc-secure-scan__phone"
			[class.gwc-secure-scan__phone--error]="formControl.touched && formControl.invalid">
			<h4>
				{{ is_photo() ? "Use your phone's camera to take a passport style photo." : "Use your mobile phone's camera to scan your documents" }}
			</h4>
			<div class="gwc-secure-scan__options">
				<section class="gwc-secure-scan__options-item">
					<h5>Send a link via text</h5>
					<mat-form-field floatLabel="always">
						<mat-label>Enter Mobile Number</mat-label>
						<input matInput 
							mask="(000) 000-0000"
							[formControl]="phone">
					</mat-form-field>
					<button mat-stroked-button
						color="primary"
						[disabled]="sendingText()"
						(click)="sendText()">
						Send Text
					</button>
				</section>
				@if (qrLink()) {
					<section class="gwc-secure-scan__options-divider">or</section>
					<section class="gwc-secure-scan__options-item gwc-secure-scan__options-item--not-mobile">
						<h5>Scan the QR code</h5>
						<qrcode [qrdata]="qrLink()"
							[width]="120"
							errorCorrectionLevel="Q"
							colorDark="#2d3342">
						</qrcode>
					</section>
					<section class="gwc-secure-scan__options-item gwc-secure-scan__options-item--mobile">
						<h5>{{ is_photo() ? 'Click the link below to take a photo.' : 'Click the link below to scan your documents'}}</h5>
						<a mat-stroked-button
							color="primary"
							[disabled]="sendingText()"
							[href]="qrLink()"
							target="_blank">
							{{ is_photo() ? 'Take a Photo' : 'Scan Documents' }}
						</a>
					</section>
				}
			</div>

			@if (formControl.touched && formControl.invalid){
				<div class="gwc-secure-scan__error">
					Please press "Send Text" to start the process before proceeding.
				</div>
			}
		</div>
		@if (props['allow_upload']) {
			<div class="gwc-secure-scan__upload">
				Or, <a (click)="uploadFile(0)">upload files</a> from your computer
			</div>
		}
	}
	@case ('phone') {
		<h4 class="gwc-secure-scan__message">
			@if (is_photo()) {
				{{ is_text() ? 'Please use the link in the text message to take your photo' : 'Please use your phone to take your photo' }}
			} @else {
				{{ is_text() ? 'Please use the link in the text message to scan your documents' : 'Please use your phone to scan your document' }}
			}
		</h4>
		<ul class="gwc-secure-scan__documents">
			@for (document of documents(); track document.document_uuid) {
				<li>
					<div class="gwc-secure-scan__label">
						@if (listening() === document.document_uuid || !isCompleted(document.document_uuid)()) {
							<mat-spinner [class.gwc-secure-scan__hidden]="listening() !== document.document_uuid && !isCompleted(document.document_uuid)()"
								diameter="20">
							</mat-spinner>
						} @else if (isCompleted(document.document_uuid)()) {
							<img alt="checkmark"
								src="assets/scanner/checkmark.svg"/>
						}
						{{ document.friendly_name }}
					</div>
				</li>
			}
		</ul>
	}
	@case ('crop') {
		<section class="gwc-secure-scan__crop">
			@if (!imageChangedEvent()) {
				@if (uploadingDocument().type === 'drivers_license_front') {
					<p>
						<strong>Upload a picture of the front of your Driver's License</strong><br/><br/>
						Please ensure the entire front of the driver's license, including the edges of the card, is visible and free from any glare that could obscure the text.
					</p>
				} @else if (uploadingDocument().type === 'drivers_license_back') {
					<p>
						<strong>Upload a picture of the back of your Driver's License</strong><br/>
						Please ensure the entire back of the driver's license, including the edges of the card, is visible and free from any glare that could obscure the text.
					</p>
				}

				<input #input 
					type="file" 
					[multiple]="false"
					class="gwc-secure-scan__input"
					accept="image/png, image/jpeg"
					(change)="fileUploaded($event)"/>
				<button mat-stroked-button
					color="primary"
					(click)="file_input.nativeElement.click()">
					Upload Document Scan
				</button>
			} @else {
				<image-cropper class="gwc-secure-scan__cropper"
					[imageChangedEvent]="imageChangedEvent()"
					[maintainAspectRatio]="true"
					[aspectRatio]="1.59 / 1"
					format="jpeg"
					output="blob"
					(imageCropped)="imageCropped($event)"
					(imageLoaded)="imageLoaded($event)"
					(cropperReady)="cropperReady()"
					(loadImageFailed)="loadImageFailed()"
				></image-cropper>
				<button mat-stroked-button
					color="primary"
					[disabled]="uploadingImage()"
					(click)="uploadCroppedImage()">
					Upload Document Scan
				</button>
			}
		</section>
	}
	@case ('completed') {
		@if (!is_photo) {
			<h4 class="gwc-secure-scan__message">
				All documents have been scanned.
			</h4>
		}
		@for (document of documents(); track document.document_uuid; let i = $index) {
			<div class="gwc-secure-scan__completed">
				<div class="gwc-secure-scan__label">
					<img src="assets/scanner/checkmark.svg"/>
					{{ document.friendly_name }}
					<button mat-stroked-button
						color="primary"
						[disabled]="sendingText()"
						(click)="retakePhoto(document, i)">
						Retake
					</button>			
				</div>
				<img class="gwc-secure-scan__completed-preview"
					[src]="getDocumentImg(document.document_uuid)"/>
			</div>
		}
	}
}