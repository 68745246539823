<section class="gwc-message">
	<header class="gwc-content-header">
		<h1>Message Center</h1>
	</header>
	<div *ngIf="message">
		<div class="gwc-message__top">
			<div class="gwc-message__main">
				<div class="gwc-message__subject">
					<button mat-icon-button
						[routerLink]="['../']"
						class="gwc-message__back">
						<svg>
							<use href="assets/svg/shared.svg#back"/>
						</svg>
					</button>
					<h2>{{ message.subject }}</h2>
				</div>
				<span>{{ message.from }} | {{ message.received_at | date: 'MM/dd/yyyy hh:mm a' }}</span>
			</div>
			<div class="gwc-message__traveler">
				<img [src]="message.traveler.avatar" [alt]="message.traveler.name" />
				<div>
					{{ message.traveler.name }}
					<span>{{ message.order.order_number }}</span>
				</div>
			</div>
		</div>
		<div class="gwc-message__body"
			[innerHtml]="sanitize(message.textAsHtml)"></div>
		<section *ngIf="message.attachments.length > 0"
			class="gwc-message__attachments">
			<h3>Attachments</h3>
			<div class="gwc-message__attachments-list">
				<a *ngFor="let attachment of message.attachments"
					target="_blank"
					[href]="attachment.url" download>
					<svg>
						<use [attr.xlink:href]="'assets/svg/shared.svg#' + getAttachmentType(attachment)"/>
					</svg>
					{{ attachment.filename }}
				</a>
			</div>
		</section>
	</div>
</section>
