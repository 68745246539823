<div class="gwc-login__section">
	<div class="gwc-setup__divider"></div>
	<h1>Account Setup</h1>
	@switch(step) {
		@case ('password') {
			<section>
				<p>Your password must be at least 8 characters and use a combination of letters, numbers, and symbols.</p>
				<form [formGroup]="setupForm" 
					(ngSubmit)="changeStep()">
					<mat-form-field appearance="fill"
						[hideRequiredMarker]="true">
						<mat-label>New Password</mat-label>
						<input matInput
							type="password"
							autocomplete="current-password"
							formControlName="password">
							@if (setupForm.get('password')?.hasError('required')) {
								<mat-error> A new password is required.</mat-error>
							} @else if (setupForm.get('password')?.hasError('minlength')) {
								<mat-error>You must enter 8 characters or more.</mat-error>
							} @else if (setupForm.get('password')?.hasError('pattern')) {
								<mat-error>Please use letters, numbers, and symbols.</mat-error>
							}
					</mat-form-field>
					<mat-form-field appearance="fill"
						[hideRequiredMarker]="true">
						<mat-label>Confirm Password</mat-label>
						<input matInput
							type="password"
							[errorStateMatcher]="passwordErrorStateMatcher"
							autocomplete="current-password"
							formControlName="password_confirmation">
							@if (setupForm.controls.password_confirmation.errors) {
								<mat-error>Please confirm your new password.</mat-error>
							} @else if (setupForm.errors) {
								<mat-error>The passwords must match.</mat-error>
							}
					</mat-form-field>
					<gwc-button color="primary"
						type="submit"
						[fullwidth]="true"
						[disabled]="submitting"
						[spinner]="submitting">
						Next
					</gwc-button>
				</form>
			</section>
		} @case('alias') {			
			<section class="gwc-setup__govmail">
				<h2>govMail Secure Notifications</h2>
				<img class="gwc-setup__govmail-banner"
					srcset="assets/login/img-govmail-setup.jpg,
									assets/login/img-govmail-setup@2x.jpg 2x"
					src="assets/login/img-govmail-setup@2x.jpg"
					alt="Account GovMail" />
				<p>
					By creating a govMail account, you will automatically enable your govWorks message center and you are acknowledging that we have permission to act as your limited agent for the limited purpose for communicating, submitting and facilitating your travel documents applications (passport, visa, driving permit, etc) through the respective government agency; such as the Passport Office, Embassy, or Consulate.
				</p>
				<p>
					The govWorks message center provides you with a secure way to communicate with the government and track the status of your government documents. Under the provisions of the Privacy Act of 1974 (Public Law 93-579), no information may be released from U.S. Government files without the prior written consent of the individual in question.
				</p>
				@if (alias) {
					<div class="gwc-setup__alias">
						<div class="gwc-setup__alias-display">
							<section>
								<div>
									govMail Account Address
								</div>
								<div class="gwc-setup__alias-display-value">
									{{ alias.alias }}&#64;{{ alias.domain }}
								</div>
							</section>

							@if (!edit) {
								<button mat-button
									(click)="editAlias()"
									class="gwc-setup__alias-display-btn"
									color="primary">
									Change
								</button>
							}
						</div>

						@if (edit) {
							<div class="gwc-setup__alias-edit">
								<mat-form-field class="gwc-setup__alias-field"
									appearance="outline"
									[hideRequiredMarker]="true">
									<input matInput
										pattern="[A-Za-z0-9\s]*"
										[formControl]="aliasControl">
									<mat-error>
										@if (aliasControl.errors?.['required']) {
											Please enter an email address.
										} @else if (aliasControl.errors?.['pattern']) {
											Please use only letters and numbers.
										} @else if (aliasControl.errors?.['taken']) {
											The email is taken. Please try a different one.									
										}
									</mat-error>
								</mat-form-field>
								<div class="gwc-setup__alias-edit-btns">
									<button mat-flat-button
										(click)="saveAlias()"
										[disabled]="!aliasControl.valid"
										class="gwc-setup__alias-edit-btn"
										color="primary">
										Save
									</button>
									<button mat-stroked-button
										(click)="cancelEdit()"
										class="gwc-setup__alias-edit-btn">
										Cancel
									</button>
								</div>
							</div>
						}

						<button mat-flat-button
							[disabled]="edit"
							(click)="setupAccount()"
							class="gwc-setup__submit"
							color="primary">
							Set Up Your Account
						</button>
					</div>
				}
			</section>
		}
	}



</div>
