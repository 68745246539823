<div class="gwc-login">
	<div class="gwc-login__content">
		<div class="gwc-login__form">
			<svg class="gwc-login__logo">
				<use href="assets/login/login.svg#logo" />
			</svg>
			@if (!setup) {
				<img class="gwc-login__robot"
					srcset="assets/login/img-wally-waving.jpg,
									assets/login/img-wally-waving@2x.jpg 2x"
					src="assets/login/img-wally-waving@2x.jpg"
					alt="Wally Robot" />
			}
			<router-outlet></router-outlet>		
		</div>
	</div>
</div>