import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { DownloadPacketComponent } from 'src/app/dialogs/download.packet/download.packet.component'
import { HeaderModule } from '../header/header.module'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { ButtonComponent } from 'src/app/components/button/button.component'

@NgModule({
  declarations: [
    DownloadPacketComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HeaderModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    ButtonComponent
  ]
})

export class DownloadPacketModule { }
