import { ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core'
import { DateTime } from 'luxon'
import { ApplicationService } from 'src/app/services/application.service'
import { OrderService } from 'src/app/services/order.service'
import { ProfileService } from 'src/app/services/profile.service'
import * as _ from 'lodash'

@Component({
  selector: 'gwc-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})

export class StepperComponent extends FieldType<FieldTypeConfig> implements OnInit {
  public current_id: number = 0
  public current_step: number = 1
  public stepForm = new FormGroup({})
  public stepForms: FormGroup[] = []
  public steps: number|undefined = undefined
  public progress: number = 0
  private params!: {[key: string]: string}
  public subscription: any
  public model_copy!: {[key: string]: string}
  public saving: boolean = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    private profileService: ProfileService,
    private applicationService: ApplicationService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super()
    this.activatedRoute.pathFromRoot.forEach((route) => {
      this.params = {...this.params, ...route.snapshot.params}
    })
  }

  ngOnInit() {
    for (let i=0; i < (this.field.fieldGroup as Array<any>).length; i++ ) {
      this.stepForms.push(new FormGroup({}))
    }

    this.listenToProgress()
    this.model_copy = _.cloneDeep(this.model)    
  }

  ngAfterViewInit() {
    this.progress = this.props['progress'] || 0
    this.countSteps()
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  public listenToProgress(): void {
    this.subscription = this.orderService.submittedSubject
      .subscribe((app_uuid: string) => {
        if (this.props['uuid'] !== app_uuid)  this.countSteps()
      })
  }

  public stepChange(direction: string): void {
    if(this.stepForms[this.current_id].valid || direction === 'back') {
      let step = direction === 'back' ? -1 : 1
      let new_step = this.current_id + step
      let field: FormlyFieldConfig | undefined = this.field.fieldGroup?.[new_step]
      
      while (this.hideStep(field)) {
        new_step += step
        field = this.field.fieldGroup?.[new_step]
      }
  
      this.current_step += step
      this.current_id = new_step
      window.scrollTo(0,0)
      this.orderService.nextPage.next(this.props['uuid'])
      if (!_.isEqual(this.model, this.model_copy)) {
        this.countSteps()
        this.saveModel()
      }
    } else {
      let form = this.stepForms[this.current_id]
      form.markAllAsTouched()
    }
  }

  private hideStep(field: FormlyFieldConfig | undefined): boolean {
    try {
      if (!field) return true

      // Needs to be here for eval
      let model = this.model
      let expression: string = field.props?.['hide'] as string
      if (!expression) return false
  
      let dt = DateTime
  
      return eval(expression)
    } catch {
      return false
    }
  }

  private countSteps(finish: boolean = false, model_changed: boolean = false) {
    let count = 0
    let valid = 0

    this.field.fieldGroup?.forEach((field, index) => {
      if (!this.hideStep(field)) {
        count += 1

        if (field && this.stepForms[index] && this.stepForms[index].valid) {
          valid +=1
        }
      }
    })

    this.steps = count
    this.changeDetectorRef.detectChanges()

    if (model_changed || this.progress !== Math.floor((valid/count)*100)) {
      this.progress = Math.floor((valid/count)*100)

      this.applicationService.saveProgress(this.props['uuid'], this.progress)
        .subscribe(response => {
          if (finish) {
            this.router.navigate(['../../'], {relativeTo: this.activatedRoute})
          }
        })
    }
  }

  public finishForm(): void {
    this.saving = true
    const current_form = this.stepForms[this.current_id]

    if (current_form.valid) {
      this.applicationService.saveProgress(this.props['uuid'], 100)
        .subscribe(response => {
          this.closeForm(false)
          this.saving = false
        })
    } else {
      current_form.markAllAsTouched()
    }
  }

  public closeForm(calculate_progress: boolean = true) {
    if (!_.isEqual(this.model, this.model_copy)) {
      this.saveModel()
      this.orderService.submittedSubject.next(this.props['uuid'])

      if (calculate_progress) {
        this.countSteps(true, true)
      }
    }

    this.router.navigate(['../../'], {relativeTo: this.activatedRoute})
  }

  private saveModel(): void {
    this.profileService.saveProfileWithApplication(this.props['uuid'], this.model)
      .subscribe()
  }

  public getOptions(id: number) {
    let options = this.options
    options.formState.active = id === this.current_id
    return options
  }
}
