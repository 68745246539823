<div class="gwc-multi-select">
	@for (option of checkboxes; track option) {
		<button mat-stroked-button
			class="gwc-multi-select__option"
			[class.gwc-multi-select__option--error]="formControl.touched && formControl.invalid"
			[class.gwc-multi-select__option--selected]="formControl.value?.includes(option.value)"
			(click)="toggleOption(option)"
			type="button"
			color="primary">
			{{ option.label }}
		</button>
	}
	@if (formControl.touched && formControl.invalid) {
		<div class="gwc-multi-select__error">
			Please select at least one option.
		</div>
	}
</div>
