<div class="gwc-dialog gwc-start-order">
  <gwc-dialog-header label="New Order"></gwc-dialog-header>
  <section class="gwc-dialog__body gwc-start-order__body">
    <h2 class="gwc-start-order__title">
      <b>Get Started.</b>
      Give this trip a friendly name.
    </h2>
    <form [formGroup]="tripForm" (ngSubmit)="updateOrder()">
      <mat-form-field floatLabel="auto"
        class="gwc-start-order__field">
        <input matInput 
          placeholder="Ex. Summer Family Trip"
          formControlName="trip_name"
          autocomplete="off">
        <mat-hint [align]="'end'">{{ trip_name.value.length }}/20</mat-hint>
      </mat-form-field>
      <div mat-dialog-actions
        class="gwc-start-order__actions">
        <button mat-button
          color="primary"
          aria-label="Skip Question"
          type="submit">
          I don't know. Skip »
        </button>
        <gwc-button color="primary"
          [fullwidth]="true"
          [disabled]="!tripForm.valid"
          aria-label="Continue"
          type="submit">
          <div class="gwc-start-order__continue">
            Continue
            <svg>
              <use href="assets/svg/shared.svg#arrow-next" />
            </svg>
          </div>
        </gwc-button>
      </div>
    </form>
  </section>
</div>
