import { Component, Input, OnInit } from '@angular/core'
import { FieldType, FieldTypeConfig } from '@ngx-formly/core'
import { selectOption } from 'src/types/wizard'

@Component({
  selector: 'gwc-button-select',
  templateUrl: './button.select.component.html',
  styleUrls: ['./button.select.component.scss']
})

export class ButtonSelectComponent extends FieldType<FieldTypeConfig> {
  public buttons: selectOption[] = []

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.buttons = this.props.options as Array<selectOption>
  }

  public selectOption(option: selectOption): void {
    this.formControl.patchValue(option.value)
  }
}
