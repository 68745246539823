<div class="gwc-social-security"> 
	<mat-form-field class="gwc-social-security__field"
		appearance="outline"
		color="primary">
		<input matInput
			mask="000-00-0000"
			[type]="show ? 'text' : 'password'"
			autocomplete="off"
			[formControl]="formControl"/>
		<mat-error class="gwc-social-security__error">
			Please enter a valid social security number.
		</mat-error>
	</mat-form-field>
	<button mat-icon-button
		class="gwc-social-security__visible"
		(click)="toggleShow()">
		<svg>
			<use [attr.xlink:href]="'assets/svg/shared.svg#visibility' + (show ? '':'-off')" />
		</svg>
	</button>
</div>
