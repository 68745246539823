import { Injectable } from '@angular/core'
import { from, map, Observable } from 'rxjs'
import { Location } from 'src/types/location'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})

export class FacilityService extends ApiService {
  public getFacilities(zip_code: string, category: string, brand?: string|null) {
    let request: {
      radius: number
      limit: number
      string?: string
      category: string
      brand?: string
    } = {
      category,
      limit:50,
      radius:500
    }

    if (brand) request.brand = brand

    return this.postRequest(`facility/near/${zip_code}`, request)
  }
}
