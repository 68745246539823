import { Component, OnInit, Inject } from '@angular/core'
import { FormControl, AbstractControl, Validators, ValidatorFn, ValidationErrors, FormBuilder, ReactiveFormsModule } from '@angular/forms'
import { DateTime } from 'luxon'
import { DateTime as BusinessDateTime } from 'luxon-business-days'
import { Application, Traveler } from 'src/types/traveler'
import { ApplicationService } from 'src/app/services/application.service'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { OrderService } from 'src/app/services/order.service'
import { CommonModule } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask'
import { TravelerDialogModule } from '../traveler.dialog/traveler.dialog.module'
import { ButtonComponent } from 'src/app/components/button/button.component'

@Component({
  selector: 'gwc-ship-date',
  templateUrl: './ship.date.component.html',
  styleUrls: ['./ship.date.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    NgxMaskDirective,
    ReactiveFormsModule,
    TravelerDialogModule
  ],
  providers: [
    provideNgxMask()
  ]
})

export class ShipDateComponent implements OnInit {
  public actionLabel!: string
  public actionbarTitle!: string
  public actionbarMessage!: string
  public actionbarButton!: string
  public shipStep = 'pick'
  public dateForm = this.formBuilder.group({
    promise_date:['', [this.validDate()]]
  })
  public confirmForm = this.formBuilder.group({
    confirm: ['', [Validators.required, this.signedName()]]
  })
  public dates: DateTime[]  = []
  public minDate = DateTime.fromObject(BusinessDateTime.now().toObject()).startOf('day')
  public maxDate = DateTime.fromObject(BusinessDateTime.now().plusBusiness({ days: 15 }).toObject())
  public confirmed = false
  public manifest_phone!: string

  constructor(
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private orderService: OrderService, 
    public dialogRef: MatDialogRef<ShipDateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {application: Application, traveler: Traveler, step: 'done'|'pick'}
  ) {
    this.manifest_phone = orderService.phone
    this.setActionBar(data.step)
  }

  ngOnInit(): void {
    this.getDates()
  }

  private getDates(): void {
    for (let i=0; i<4; i++) {
      this.dates.push(BusinessDateTime.now().plusBusiness({days: i}))
    }
  }

  private setActionBar(step: string) {
    if (step === 'pick') {
      this.actionLabel = 'Document Shipping Confirmation'
      this.actionbarTitle = 'Document Shipping Confirmation'
      this.actionbarMessage = 'Select a shipping date for your documents'
      this.actionbarButton = 'Continue'
      this.shipStep = 'pick'
    } else if (step === 'done') {
      this.shipStep = 'done'
      this.actionLabel = 'Your application is being reviewed.',
      this.actionbarTitle = `${this.data.traveler.friendly_name}'s Application Progress`,
      this.actionbarMessage = `Please call us now to complete your process at ${this.manifest_phone}`
      this.actionbarButton = 'Download Documents'
    }
  }

  public get promise_date(): FormControl {
    return this.dateForm.controls.promise_date
  }

  public get confirm(): FormControl {
    return this.confirmForm.controls.confirm
  }

  public getDate(control: AbstractControl): DateTime | null {
    if (control && control.value) {
      return DateTime.fromFormat(control.value, 'MM/dd/yyyy')
    }

    return null
  }

  public dateSelected(value: DateTime): void {
    this.promise_date.patchValue(value?.toFormat('MM/dd/yyyy'))
  }

  private validDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control?.value) {
        let date =  DateTime.fromFormat(control.value, 'MM/dd/yyyy')

        if (!date.isValid) return { 'invalid_date' : true }
        else if (date < this.minDate) return { 'past' : true }
        else if (date > this.maxDate) return { 'future': true }
      }

      return {}
    }
  }

  public canProceed(): boolean {
    return !(this.shipStep === 'pick' && this.promise_date.valid) && !(this.shipStep === 'confirm' && this.confirmed)
  }

  public proceed(): void {
    if (this.shipStep === 'pick') {
      this.shipStep = 'confirm'
      this.actionbarMessage = 'Confirm and acknowledgement'
    } else if (this.shipStep === 'confirm') {
      this.setPromiseDate()
    } else if (this.shipStep === 'done') {
      this.dialogRef.close('download')
    }
  }

  private signedName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const signed = this.data.traveler && control.value !== this.data.traveler.friendly_name
      return signed ? { signed: { value: control.value }} : null
    }
  }

  public confirmedSigned(): void {
    this.confirmed = true
    this.confirm.disable()
  }

  private setPromiseDate(): void {
    let date = DateTime.fromFormat(this.promise_date.value, 'MM/dd/yyyy').toFormat('yyyy-MM-dd')
    this.applicationService.setPromiseDate(this.data.application.uuid, date, this.promise_date.value)
      .subscribe(response => {
        if (response.status === 500) {
          this.setActionBar('done')
        } else {
          this.dialogRef.close()
        }
      })
  }

  public getErrorMessage(): string {
    let errors = this.promise_date.errors

    if (errors) {
      if (errors['past']) {
        return `Shipping date can't be in the past.`
      } else if (errors['future']) {
        return `Shipping date can't be later than ${this.maxDate.toFormat('MM/dd/yyyy')}.`
      }
    }

    return 'Please enter a valid date(MM/DD/YYYY).'
  }
}
