import { AbstractControl, ValidationErrors } from "@angular/forms"
import { FormlyFieldConfig } from "@ngx-formly/core"
import { DateTime } from "luxon"

export function POBoxboxValidator(control: AbstractControl): ValidationErrors {
  if (!control.value) return {}

  let check_string = control.value.toLowerCase().replaceAll('.', '').replaceAll(' ', '')
  return check_string.includes('pobox') ? {pobox: true} : {}
}

export const decimalValidator = (control: AbstractControl): ValidationErrors => {
  if (!control.value) return {}

  let int_part = parseInt(control.value)

  return int_part !== control.value ? {decimal: true} : {}
}

export const validDate = (control: AbstractControl, filed: FormlyFieldConfig) => {
  if (control.value && control.valid) {
    return DateTime.fromFormat(control.value, 'MM/dd/yyyy').isValid ? {} : { 'date': true }
  }
  
  return null
}

export const futureValidator = (control: AbstractControl, filed: FormlyFieldConfig) => {
  if (control.value && control.valid) {
    return DateTime.fromFormat(control.value, 'MM/dd/yyyy').toMillis() < DateTime.now().toMillis() ? null : { future: true }
  }
  
  return null
}

export const fromValidator = (control: AbstractControl, filed: FormlyFieldConfig) => {
  if (control.value && control.valid) {
    const toControl: AbstractControl | undefined | null = filed.formControl?.parent?.get('to')

    if (toControl?.valid) {
      const from = DateTime.fromFormat(control.value, 'MM/dd/yyyy').toMillis()
      const to = DateTime.fromFormat(toControl.value, 'MM/dd/yyyy').toMillis()

      return from > to ? { from: 'From date must be before To.' } : null
    }
  }
  
  return null
}

export const toValidator = (control: AbstractControl, filed: FormlyFieldConfig) => {
  if (control.value && control.valid) {
    const fromControl: AbstractControl | undefined | null = filed.formControl?.parent?.get('from')

    if (fromControl?.valid) {
      const to = DateTime.fromFormat(control.value, 'MM/dd/yyyy').toMillis()
      const from = DateTime.fromFormat(fromControl.value, 'MM/dd/yyyy').toMillis()

      return from > to ? { from: 'To date must be after From.' } : null
    }
  }
  
  return null
}
