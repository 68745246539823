import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { OrderService } from 'src/app/services/order.service'
import { TravelerService } from 'src/app/services/traveler.service'
import { Itinerary, OrderTraveler } from 'src/types/order'

@Component({
  selector: 'gwc-travelers',
  templateUrl: './travelers.component.html',
  styleUrls: ['./travelers.component.scss']
})

export class TravelersComponent implements OnInit {
  public order_uuid!: string
  public travelers: OrderTraveler[] | undefined
  public itinerary!: Itinerary
  public loading: boolean = false

  constructor(
    private orderService: OrderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private travelerService: TravelerService
  ) {
    if (this.activatedRoute.parent) {
      this.order_uuid = this.activatedRoute.parent.snapshot.paramMap.get('order_uuid') || ''
    }
  }

  ngOnInit(): void {
    this.getTravelers()
  }

  private getTravelers(): void {
    this.orderService.getOrderDetails(this.order_uuid, 'travelers')
      .subscribe(response => {
        this.travelers = response
      })
  }

  public getStarted(uuid: string) {
    // Change status here
    this.loading = true
    this.travelerService.updateTravelerToPending(uuid)
      .subscribe(response => {
        this.router.navigate(['traveler', uuid], { relativeTo: this.activatedRoute})
        this.loading = false
      })
  }
}
