<div class="gwc-traveler-dialog">
  <div class="gwc-dialog__body gwc-traveler-dialog__body"
    [ngClass]="{'gwc-traveler-dialog__body--full': full }">
    <gwc-dialog-header [darkBackground]="false" 
      [label]="label"
      [shadow]="true">
    </gwc-dialog-header>
    <ng-content select="[body]"></ng-content>
  </div>
  <div class="gwc-traveler-dialog__btm-bar">
    <ng-content select="[actions]"></ng-content>
  </div>
</div>
