<section class="gwc-orders">
	<header class="gwc-content-header">
		<h1>My Orders</h1>
	</header>
	<div *ngFor="let order of orders"
		class="gwc-orders__order gwc-card">
		<div class="gwc-orders__orders-source gwc-orders__orders-source--{{order.source.value}}">
			{{ order.source.label }}
		</div>
		<div class="gwc-orders__order-label">
			<h1>{{ order.label }}</h1>
		</div>
		<div class="gwc-orders__order-details">
			<div>
				Placed on: {{ order.created_at | date: 'MMMM d, y' }}
			</div>
			<div>
				Order Number: {{ order.order_number }}
			</div>
		</div>
		<button mat-flat-button
			*ngIf="order.status === 'new'; else continue"
			class="gwc-orders__order-btn"
			color="primary"
			(click)="startOrder(order.uuid)">
			Start Order
		</button>
		<ng-template #continue>
			<button mat-stroked-button
				[routerLink]="['/order', order.uuid]"
				[disabled]="order.status === 'canceled'"
				class="gwc-orders__order-btn"
				color="primary">
				<span *ngIf="order.status !== 'canceled'">Continue Order</span>
				<span *ngIf="order.status === 'canceled'">Canceled</span>
			</button>
		</ng-template>
	</div>
</section>
