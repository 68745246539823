import { Component } from '@angular/core'
import { FieldArrayType } from '@ngx-formly/core'

@Component({
  selector: 'gwc-multiple',
  templateUrl: './multiple.component.html',
  styleUrls: ['./multiple.component.scss']
})

export class MultipleComponent extends FieldArrayType {}
