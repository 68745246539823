import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, RouterModule } from '@angular/router'
import { ItineraryWidgetComponent } from '../itinerary.widget/itinerary.widget.component'

@Component({
  selector: 'gwc-order-layout',
  templateUrl: './order.layout.component.html',
  styleUrls: ['./order.layout.component.scss'],
  standalone: true,
  imports: [
    ItineraryWidgetComponent,
    RouterModule
  ]
})

export class OrderLayoutComponent implements OnInit {
  public params!: {[key: string]: string}

  constructor(
    private activatedRoute: ActivatedRoute
  ) {
    let route = this.activatedRoute.snapshot
    this.params = route.params

    while(route.parent) {
      route = route.parent
      this.params = {
        ...this.params,
        ...route.params
      }
    }
  }

  ngOnInit(): void {
  }
}
