import { Component, Input, OnInit } from '@angular/core'
import { FieldType, FieldTypeConfig } from '@ngx-formly/core'
import { selectOption } from 'src/types/wizard'

@Component({
  selector: 'gwc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})

export class CheckboxComponent extends FieldType<FieldTypeConfig> {
  public label!: string

  ngOnInit(): void {
    this.label = this.props.label as string
  }

  // public selectOption(option: selectOption): void {
  //   this.formControl.patchValue(option.value)
  // }
}
