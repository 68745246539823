<div class="gwc-location-finder">
	<section class="gwc-location-finder__top">
		<h3>
			{{ props['heading'] }}
		</h3>
		<mat-form-field class="gwc-location-finder__top-zip">
			<mat-label>Zip Code</mat-label>
			<input matInput 
				[formControl]="zipCodeControl"/>
		</mat-form-field>
		<button mat-flat-button
			color="primary"
			(click)="getFacilities()">
			Search
		</button>
	</section>
	<h3 class="gwc-location-finder__label">
		Select a location:
	</h3>
	<section class="gwc-location-finder__body">
		<div class="gwc-location-finder__list">
			<ul *ngIf="facilities.length > 0">
				<li class="gwc-location-finder__list-item"
					*ngFor="let facility of facilities"
					(click)="selectFacility(facility)"
					[class.gwc-location-finder__list-item--selected]="formControl.value && facility.uuid === formControl.value.uuid">
					<h4>{{ facility.company ? companies[facility.company] : facility.name }}</h4>
					<p>
						{{ facility.address.address_1 }} {{ facility.address.address_2 ? ', ' + facility.address.address_2 : '' }}<br/>
						{{ facility.phone | phone: 'US' }} <br/>
						{{ facility.distance | number:'1.2-2'}} miles away
					</p>
				</li>
			</ul>
		</div>
		<div *ngIf="formControl.value && formControl.value.address; else noLocations"
			class="gwc-location-finder__selected">
			<section>
				<div class="gwc-location-finder__selected-header">
					I can go to this <strong>{{ formControl.value.company ? companies[formControl.value.company] : formControl.value.name }}</strong>
				</div>
				<div class="gwc-location-finder__selected-item gwc-location-finder__selected-item--address">
					{{ formControl.value.address.address_1 }} {{ formControl.value.address.address_2 ? ', ' + formControl.value.address.address_2 : '' }} <br/>
					{{ formControl.value.address.city }}, {{ formControl.value.address.state }} {{ formControl.value.address.zip }}
				</div>
				<div class="gwc-location-finder__selected-item gwc-location-finder__selected-item--phone">
					{{ formControl.value.phone | phone: 'US' }}
				</div>
				<ul *ngIf="formControl.value.hours"
					class="gwc-location-finder__selected-item gwc-location-finder__selected-item--hours">
					<li *ngFor="let day of weekdays | keyvalue: originalOrder | filter: hoursAvailable">
						<span>{{ day.value }}</span> {{ formControl.value.hours[$any(day.key)].open }} - {{ formControl.value.hours[$any(day.key)].close }}
					</li>
				</ul>
				<div *ngIf="formControl.value.comments"
					class="gwc-location-finder__selected-item gwc-location-finder__selected-item--comments">
					{{ formControl.value.comments }}
				</div>
			</section>
			<div class="gwc-location-finder__map">
				<google-map	width="100%"
					height="100%"
					[options]="mapOptions"
					[center]="mapCenter"
					[zoom]="14">
					<map-marker [position]="mapCenter"
					></map-marker>
				</google-map>
			</div>
		</div>
		<ng-template #noLocations>
			<div class="gwc-location-finder__empty">
				No locations found within 500 miles from the specified zipcode.<br /> Please try a different zip code.
			</div>
		</ng-template>
	</section>
</div>
