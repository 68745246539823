import { Injectable } from '@angular/core'
import { Validators } from '@angular/forms'
import { map, of } from 'rxjs'
import { User } from 'src/types/user'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})

export class UserService extends ApiService {
  public loginForm = this.formBuilder.nonNullable.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  })
  public user: User|null = null

  public logIn(credentials: {email: string, password: string}) {
    return this.postRequest('auth/login', credentials)
      .pipe(
        map(response => {
          if (response.status !== 401) {
            this.user = response
          }

          return response
        })
      )
  }

  public getUser() {
    if (this.user) {
      return of(this.user)
    } 
    
    return this.check()
  }

  public check() {
    return this.getRequest('check')
      .pipe(
        map((response) => {
          if (response.uuid) {
            if (response.roles.includes('customer')) {
              this.user = response
              return response
            } else {
              this.logout().subscribe()
              return null
            }
          } else {
            let url = this.router.routerState.snapshot.url
            this.user = null

            if (!url.includes('reset') && url !== '/login') {
              return this.router.navigate(['login'])
            }

            return response
          }
        })
      )
  }

  public sendPasswordReset(email: string) {
    return this.postRequest('auth/password/email', {email})
  }

  public resetPassword(credentials: { token: string, password: string, password_confirmation: string, email: string}) {
    return this.postRequest('auth/password/reset', credentials)
  }

  public createPassword(credentials: {password: string, password_confirmation: string}) {
    return this.postRequest('user/create/password', credentials)
  }

  public logout() {
    return this.getRequest('auth/logout')
  }

  public getUserAlias() {
    return this.getRequest('mail/alias')
  }

  public saveUserAlias(email: string) {
    return this.postRequest('mail/alias', {email})
  }

  public requestLoginCode(email:String) {
    return this.postRequest('auth/password/access_code', {email})
  }
}
