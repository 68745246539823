import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'gwc-traveler-dialog',
  templateUrl: './traveler.dialog.component.html',
  styleUrls: ['./traveler.dialog.component.scss']
})

export class TravelerDialogComponent {
  @Input() full!: boolean
  @Input() label!: string
  @Input() closeBtn!: boolean
  @Output() close = new EventEmitter()

  public closeDialog(): void {
    this.close.emit()
  }
}
