import { Component, OnInit } from '@angular/core'
import { MailService } from 'src/app/services/mail.service'
import { Message } from 'src/types/mail'

@Component({
  selector: 'gwc-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss']
})

export class InboxComponent implements OnInit {
  public messages!: Message[]

  constructor(
    private mailService: MailService
  ) { }

  ngOnInit(): void {
    this.getMessages()
  }

  private getMessages(): void {
    this.mailService.getAllMessages()
      .subscribe(response => {
        this.messages = response.data
      })
  }
}
