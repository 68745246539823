import { Component, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { FormlyFieldConfig } from '@ngx-formly/core'
import { ApplicationService } from 'src/app/services/application.service'
import { OrderService } from 'src/app/services/order.service'
import { Application } from 'src/types/traveler'
import { profile } from './profile'
import * as _ from 'lodash'

export interface FormStep {
  icon: string
  id: string
  label: string
  hint?: string
  fields: FormlyFieldConfig[]
}

@Component({
  selector: 'gwc-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})

export class FormComponent implements OnInit {
  public form = new FormGroup({})
  public model: any = {}
  public application!: Application
  public steps_profile: FormlyFieldConfig[] = profile
  public steps_idp!: FormlyFieldConfig[]
  public app_uuid!: string
  public traveler_uuid!: string

  constructor(
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    private applicationService: ApplicationService
  ) {
    this.traveler_uuid = this.activatedRoute.parent?.snapshot.params['traveler_uuid']
    this.app_uuid = this.activatedRoute.snapshot.params['app_uuid']
  }

  ngOnInit(): void {
    this.getApplication()  
  }

  public getApplication(): void {
    this.orderService.getApplication(this.traveler_uuid, this.app_uuid)
      .subscribe({
        next: response => {
          this.model = response.model        
          this.application = response.application
        },
        error: error => {
        }
      })
  }
}
