import { Injectable } from '@angular/core'
import { from, map, Observable } from 'rxjs'
import { Location } from 'src/types/location'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})

export class LocationService extends ApiService {
  private countries: Location[]|undefined
  private states: Location[]|undefined

  public getCountries(): Observable<Location[]> {
    if (!this.countries) {
      return this.getRequest(`populate/countries`)
        .pipe(
          map((response) => {
            this.countries = response

            return response
          })
        )
    }

    return from([this.countries])
  }

  getStates(): Observable<Location[]> {
    if (!this.states) {
      return this.getRequest(`populate/states`)
        .pipe(
          map((response) => {
            this.states = response

            return response
          })
        )
    }

    return from([this.states])
  }
}
