import { Component } from '@angular/core'
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatIconModule } from '@angular/material/icon'
import { HeaderModule } from '../header/header.module'
import { MatDialogModule } from '@angular/material/dialog'
import { MatInputModule } from '@angular/material/input'
import { MatButtonModule } from '@angular/material/button'
import { ButtonComponent } from 'src/app/components/button/button.component'

@Component({
  selector: 'gwc-start-order',
  templateUrl: './start.order.component.html',
  styleUrls: ['./start.order.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    FormsModule,
    HeaderModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule
  ]
})

export class StartOrderComponent {
  public tripForm = new FormGroup({
    trip_name: new FormControl('', [ 
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(20) 
    ])
  })

  constructor(
    private startOrder: MatDialogRef<StartOrderComponent>
  ) {}

  public get trip_name(): FormControl {
    return this.tripForm.controls.trip_name
  }

  public updateOrder(): void {
    this.startOrder.close({ trip_name: this.trip_name.value })
  }
}
