import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { RouterModule } from '@angular/router'
import { LocationService } from 'src/app/services/location.service'
import { OrderService } from 'src/app/services/order.service'
import { Itinerary } from 'src/types/order'
import { Location } from 'src/types/location'
import { Subscription } from 'rxjs'
import { DatePipe } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'

@Component({
  selector: 'gwc-itinerary-widget',
  templateUrl: './itinerary.widget.component.html',
  styleUrls: ['./itinerary.widget.component.scss'],
  standalone: true,
  imports: [
    DatePipe,
    MatButtonModule,
    RouterModule
  ]
})

export class ItineraryWidgetComponent implements OnInit, OnDestroy {
  @Input() order_uuid!: string

  public itinerary!: Itinerary
  public countries!: Location[]
  public itinerarySubscription!: Subscription

  constructor(
    private orderService: OrderService,
    private locationService: LocationService
  ) {}

  ngOnInit(): void {
    this.getCountries()
    this.itinerarySubscription = this.orderService.itineraryUpdated.subscribe(() => {
      this.getItinerary()
    })
  }

  ngOnDestroy(): void {
    this.itinerarySubscription.unsubscribe()
  }

  private getCountries(): void {
    this.locationService.getCountries()
      .subscribe(response => {
        this.countries = response
        this.getItinerary()
    })
  }

  private getItinerary(): void {
    this.orderService.getOrderDetails(this.order_uuid, 'itinerary')
      .subscribe(response => {
        this.itinerary = response

        this.itinerary.destinations.map(item => {
          if (item.country) {
            let country = this.countries.filter(country => country.label === item.country)[0]

            if (country) {
              item.formatted = country
            }
          } 

          return item
        })
      })
  }
}
