<section class="gwc-order-layout">
	<div class="gwc-order-layout__main">
		<header class="gwc-content-header">
			<section class="gwc-order-nav">
				<a [routerLink]="['.']"
					[routerLinkActiveOptions]="{exact: true}"
					class="gwc-order-nav__link"
					routerLinkActive="gwc-order-nav__link--active">
					Travelers
				</a>
				<a [routerLink]="['invoices']"
					class="gwc-order-nav__link"
					routerLinkActive="gwc-order-nav__link--active">
					Invoices
				</a>
				<a [routerLink]="['itinerary']"
					class="gwc-order-nav__link"
					routerLinkActive="gwc-order-nav__link--active">
					Itinerary
				</a>
			</section>
		</header>
		<router-outlet></router-outlet>
	</div>
	<gwc-itinerary-widget [order_uuid]="params['order_uuid']"></gwc-itinerary-widget>
</section>

