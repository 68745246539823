import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { InvoicesComponent } from './order.layout/invoices/invoices.component'
import { OrderComponent } from './order.component'
import { FormComponent } from './traveler/form/form.component'
import { TravelerComponent } from './traveler/traveler.component'
import { TravelersComponent } from './order.layout/travelers/travelers.component'
import { OrderLayoutComponent } from './order.layout/order.layout.component'
import { ItineraryComponent } from './order.layout/itinerary/itinerary.component'

const orderRoutes: Routes = [
  {
    path: 'order/:order_uuid',
    component: OrderComponent,
    data: {
      breadcrumb: 'order_uuid',
      breadcrumbLabel: 'Order'
    },
    children: [
      {
        path: '',
        data: {
          breadcrumb: null,
          breadcrumbLabel: null
        },
        component: OrderLayoutComponent,
        children: [
          {
            path: '',
            data: {
              breadcrumb: null,
              breadcrumbLabel: null
            },
            component: TravelersComponent,
          },
          {
            path: 'invoices',
            data: {
              breadcrumbLabel: 'Invoices'
            },
            component: InvoicesComponent
          },
          {
            path: 'itinerary',
            data: {
              breadcrumbLabel: 'Itinerary'
            },
            component: ItineraryComponent
          },
        ]
      },
      {
        path: 'traveler/:traveler_uuid',
        data: {
          breadcrumb: 'traveler_uuid',
          breadcrumbLabel: 'Traveler'
        },
        children: [
          {
            path: '',
            data: {
              breadcrumb: null,
              breadcrumbLabel: null
            },
            component: TravelerComponent, 
          },
          {
            path: 'app/:app_uuid',
            data: {
              breadcrumb: 'app_uuid',
              breadcrumbLabel: 'Application'
            },
            component: FormComponent
          }
        ]
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(orderRoutes)],
  exports: [RouterModule]
})

export class OrderRoutingModule { }
