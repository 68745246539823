import { Component } from '@angular/core'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { MatInputModule } from '@angular/material/input'
import { ActivatedRoute, Router } from '@angular/router'
import { ButtonComponent } from 'src/app/components/button/button.component'
import { UserService } from 'src/app/services/user.service'
import { PasswordErrorStateMatcher } from 'src/matchers/password.error.matcher'
import { validatePasswordMatch } from 'src/validators/password.validator'

@Component({
  selector: 'gwc-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    MatInputModule,
    ReactiveFormsModule
  ]
})

export class ResetComponent {
  public error: boolean = false
  public submitting: boolean = false
  public passwordErrorStateMatcher = new PasswordErrorStateMatcher()
  public resetForm = this.formBuilder.nonNullable.group({
    email: ['', Validators.required],
    password: ['', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern(/^(?=.*[A-Za-z]{1,})(?=.*\d{1,})(?=.*[\W_]{1,})[\w\W_]*$/i)
    ]],
    password_confirmation: ['', Validators.required],
    token: ['', Validators.required]
  }, {validators: [validatePasswordMatch()]})

  constructor(
    private formBuilder: FormBuilder,
    public userService: UserService,
    private router: Router,
    route: ActivatedRoute
  ) {
    this.resetForm.controls.token.patchValue(route.snapshot.params['token'])
  }

  public reset() {
    if (this.resetForm.valid) {
      this.userService.resetPassword(this.resetForm.getRawValue())
        .subscribe(response => {
          this.submitting = false
          if (response.status === 'ok') {
            this.router.navigate(['/orders'])
          } else {
            this.router.navigate(['/forgot'])
          }
        })
    }
  }
}
