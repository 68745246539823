import { Component, OnInit, Sanitizer } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { MailService } from 'src/app/services/mail.service'
import { Attachment, MessageDetails } from 'src/types/mail'

@Component({
  selector: 'gwc-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})

export class MessageComponent implements OnInit {
  private message_uuid!: string
  public message!: MessageDetails

  constructor(
    private activatedRoute: ActivatedRoute,
    private mailService: MailService,
    private sanitizer: DomSanitizer
  ) {
    this.message_uuid = this.activatedRoute.snapshot.params['message_uuid']
  }

  ngOnInit(): void {
    this.getMessage()
  }

  private getMessage(): void {
    this.mailService.getMessage(this.message_uuid)
      .subscribe(response => {
        this.message = response
      })
  }

  public sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }

  public getAttachmentType(attachment: Attachment): string {
    return attachment.contentType.includes('image') || attachment.contentType.includes('wmz') ? 'image' : 'document'
  }
}
