import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { OrderService } from 'src/app/services/order.service'
import { OrderSummary, Itinerary } from 'src/types/order'

@Component({
  selector: 'gwc-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})

export class OrderComponent implements OnInit {
  public uuid!: string
  public order!: OrderSummary
  public itinerary!: Itinerary
  public phone: string | undefined

  constructor(
    private activatedRoute: ActivatedRoute,
    private orderService: OrderService
  ) {
    this.uuid = this.activatedRoute.snapshot.params['order_uuid']
  }

  ngOnInit(): void {
    this.getSummary()
  }

  private getSummary(): void {
    this.orderService.getOrderDetails(this.uuid, 'summary')
      .subscribe(response => {
        this.order = response
        this.phone = this.order && this.order.source !== 'idp' ? this.order.phone_number : undefined
      })
  }
}
