import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { Observable } from 'rxjs'
import { Profile } from 'src/types/user'

@Injectable({
  providedIn: 'root'
})

export class ProfileService extends ApiService {
  // private options = {
  //   withCredentials: false
  // }

  // public submitApplication(data: any): Observable<any> {
  //   return this.http
  //     .post<any>('http://localhost:9225/v1/tasks/invokePlugin', data, this.options)
  // }

  public saveProfile(model: {[key: string]: any}) {
    return this.postRequest('profile/updateModel', {model})
  }

  public saveProfileWithApplication(app_uuid: string, model: {[key: string]: any}) {
    if (model['application']) {
      model['application'].application_uuid = app_uuid
    }
    return this.postRequest('profile/updateModel', {model})
  }

  public getProfiles(): Observable<Profile[]> {
    return this.getRequest('user/profiles')
  }
}
