import { Component, OnInit } from '@angular/core'
import { UserService } from 'src/app/services/user.service'
import { User } from 'src/types/user'

@Component({
  selector: 'gwc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  public user!: User

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.checkUser()
  }

  private checkUser(): void {
    this.userService.getUser()
      .subscribe((response) => {
        this.user = response
      })
  }
}
