import { DatePipe } from '@angular/common'
import { Component, Input } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { Profile } from 'src/types/user'

@Component({
    selector: 'gwc-profile-card',
    standalone: true,
    imports: [
        MatButtonModule,
        DatePipe
    ],
    templateUrl: './card-profile.component.html',
    styleUrl: './card-profile.component.scss'
})

export class CardProfileComponent {
    @Input({ required: true }) profile!: Profile
}